import routes from 'config/routes'

import {
  SET_ACTIVE_TOOL,
  SET_ACTIVE_TOOL_CATEGORY,
  SET_SNAP_TOGGLE,
  SET_ORTHO_MODE_TOGGLE,
  SET_CONTINUOUS_MODE_TOGGLE,
  UPDATED_OVERLAY_ACTIVE_STATUS,
  setActiveTool,
  setActiveToolCategory,
} from './actions'
import {
  ADD_OBJECT,
  ADD_PRODUCT,
  ADD_DIMENSION,
  ADD_DOOR,
  ADD_UTILITY_BOX,
  ADD_OBSTRUCTION,
  ADD_COMFORT_POINT,
  ADD_COMFORT_ZONE,
  deleteBackgroundImage,
  revertRoofSections,
} from '../objects'
import {
  deleteSelectedObjects,
  duplicateSelectedObjects,
  duplicateGridObjects,
  DELETE_SELECTED_OBJECTS,
  DESELECT_OBJECTS,
  SELECT_OBJECTS,
} from '../selectedObjects'
import { isContinuousModeEnabled } from './selectors'
import { mostRecentSelectedObjectStateOfClassNames } from '../selectedObjects/selectors'
import * as layers from '../layers'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import CATEGORY_KEYS from 'config/toolCategoryKeys'
import { showPanel } from '../panel'
import { DETAILED_OBSTRUCTION_PANEL } from '../panel/types'

import { onGenerateAutoDimensions } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { guid } from 'lib/utils'
import store from 'store'
import { createReducer } from '@reduxjs/toolkit'

export const tools = {
  [LAYER_KEYS.ROOFS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        ELEVATION_POINT_TOOL: {
          text: 'Elev Point',
          icon: 'elevationPoint',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        ELEVATION_LINE_TOOL: {
          text: 'Elev Line',
          icon: 'caretUp',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    TEMPLATE_TOOL: {
      text: 'Add',
      icon: 'plus',
      onClick: null,
      to: routes.modals.roofTemplates,
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.ROOF_SECTIONS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        ROOF_SECTION_TOOL: {
          text: 'Slice',
          icon: 'cut',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DELETE_TOOL: {
          text: 'Revert',
          icon: 'undo',
          onClick: ({ dispatch }) =>
            dispatch(
              revertRoofSections(
                mostRecentSelectedObjectStateOfClassNames(
                  CLASS_NAMES.ROOF_SECTION,
                  store.getState()
                )
              )
            ),
        },
      },
    },
  },
  [LAYER_KEYS.PRODUCTS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch, selectedObject }) => {
            // If in continuous mode and there's a selected object,
            // `selectedObject` will exist
            if (selectedObject) {
              const model = Object.assign({}, selectedObject)
              model.id = guid()
              dispatch(setActiveTool({ tool: 'PRODUCT_TOOL', props: model }))
            } else {
              dispatch(duplicateSelectedObjects())
            }
          },
        },
        DISTRIBUTION_TOOL: {
          text: 'Grid',
          icon: 'grid',
          onClick: null,
          to: routes.modals.distributeProduct,
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.DOORS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DISTRIBUTION_TOOL: {
          text: 'Grid',
          icon: 'grid',
          onClick: null,
          to: routes.modals.distributeDoor,
        },
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.UTILITY_BOXES]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.COMFORT_POINTS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.COMFORT_ZONES]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        LINE_TOOL: {
          text: 'Custom',
          icon: 'draw',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        COMFORT_ZONE_TOOL: {
          text: 'Rect',
          icon: 'square',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.INTERIOR_WALLS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        LINE_TOOL: {
          text: 'Custom',
          icon: 'draw',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        RECTANGLE_TOOL: {
          text: 'Rect',
          icon: 'square',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        ELLIPSE_TOOL: {
          text: 'Circle',
          icon: 'circle',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.EXTERIOR_WALLS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        LINE_TOOL: {
          text: 'Custom',
          icon: 'draw',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        DRAW_TOOL: {
          text: 'Draw',
          icon: 'edit',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        RECTANGLE_TOOL: {
          text: 'Rect',
          icon: 'square',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        ELLIPSE_TOOL: {
          text: 'Circle',
          icon: 'circle',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    TEMPLATE_TOOL: {
      text: 'Add',
      icon: 'plus',
      onClick: null,
      to: routes.modals.shapeTemplates,
      disabled: false,
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.OBSTRUCTIONS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        LINE_TOOL: {
          text: 'Custom',
          icon: 'draw',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        CUBE_TOOL: {
          text: 'Rect',
          icon: 'square',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        CYLINDER_TOOL: {
          text: 'Circle',
          icon: 'circle',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    ADD_TOOL: {
      text: 'Add',
      icon: 'plus',
      onClick: ({ dispatch }) =>
        dispatch(showPanel({ type: DETAILED_OBSTRUCTION_PANEL })),
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          dispatch: false,
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DISTRIBUTION_TOOL: {
          text: 'Grid',
          icon: 'grid',
          onClick: null,
          to: routes.modals.distributeObstruction,
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.DIMENSIONS]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    [CATEGORY_KEYS.DRAW]: {
      text: 'Draw',
      icon: 'draw',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DIMENSION_TOOL: {
          text: 'Measure',
          icon: 'ruler',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
      },
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          dispatch: false,
          onClick: ({ dispatch }) => dispatch(duplicateSelectedObjects()),
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
  [LAYER_KEYS.BACKGROUND_IMAGE]: {
    SELECT_TOOL: {
      text: 'Select',
      icon: 'select',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    MULTI_SELECT_TOUCH_TOOL: {
      text: 'Multi Sel.ect',
      icon: 'multiSelect',
      onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
    },
    UPLOAD_TOOL: {
      text: 'Add',
      icon: 'plus',
      to: '/upload-image/background',
    },
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        MOVE_TOOL: {
          text: 'Move',
          icon: 'move',
          onClick: ({ dispatch, tool }) => dispatch(setActiveTool({ tool })),
        },
        SCALE_TOOL: {
          text: 'Scale',
          icon: 'ruler',
          to: routes.modals.scaleImage,
        },
        EDIT_TOOL: {
          text: 'Crop/Rotate',
          icon: 'edit',
          to: routes.modals.editImage,
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteBackgroundImage()),
        },
      },
    },
  },
  [LAYER_KEYS.GRID_BOX]: {
    [CATEGORY_KEYS.EDIT]: {
      text: 'Edit',
      icon: 'edit',
      onClick: ({ dispatch, tool }) =>
        dispatch(setActiveToolCategory({ activeToolCategory: tool })),
      tools: {
        DUPLICATION_TOOL: {
          text: 'Copy',
          icon: 'duplicate',
          onClick: ({ dispatch }) => dispatch(duplicateGridObjects()),
          disabled: false,
        },
        DELETE_TOOL: {
          text: 'Delete',
          icon: 'trash',
          onClick: ({ dispatch }) => dispatch(deleteSelectedObjects()),
        },
      },
    },
  },
}

// TODO: Remove functions from state
export const initialState = {
  activeTool: 'SELECT_TOOL',
  activeToolProps: {},
  activeToolTargetLayer: 'ACTIVE_LAYER',
  activeToolCategory: /** @type {string|null} */(null),
  isSnapEnabled: true,
  isOrthoModeEnabled: false,
  isContinuousModeEnabled: false,
  isOverlayActive: false, // Used to tell when the 'Overlay' has the cursor
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(SET_ACTIVE_TOOL, (state, action) => {
      const activeToolCategory = state.activeToolCategory
        ? null
        : state.activeToolCategory
      return {
        ...state,
        activeTool: action.payload.tool,
        activeToolProps: { ...action.payload.props },
        activeToolCategory,
      }
    })
    .addCase(SET_ACTIVE_TOOL_CATEGORY, (state, action) => {
      // We want to set the `state.activeToolCategory` to `null` when the same
      // category is passed in, thus acting as a toggle.
      const activeToolCategory =
        state.activeToolCategory === action.payload.activeToolCategory
          ? null
          : action.payload.activeToolCategory
      return {
        ...state,
        activeToolCategory,
      }
    })
    .addCase(SET_SNAP_TOGGLE, (state, action) => {
      state.isSnapEnabled = action.payload.isSnapEnabled
    })
    .addCase(SET_CONTINUOUS_MODE_TOGGLE, (state, action) => {
      return {
        ...state,
        isContinuousModeEnabled: action.payload.isContinuousModeEnabled,
      }
    })
    .addCase(SET_ORTHO_MODE_TOGGLE, (state, action) => {
      return {
        ...state,
        isOrthoModeEnabled: action.payload.isOrthoModeEnabled,
      }
    })
    .addCase(UPDATED_OVERLAY_ACTIVE_STATUS, (state, action) => {
      return {
        ...state,
        isOverlayActive: action.payload.isOverlayActive,
      }
    })
    .addCase(layers.SET_CURRENT_LAYER, (state, action) => {
      return {
        ...state,
        activeTool: 'SELECT_TOOL',
        activeToolProps: {},
      }
    })
    .addCase(ADD_OBSTRUCTION, (state, action) => {
      const obstructionType = action.payload.obstruction.obstructionType
      const isBasicObstruction = obstructionType === 'basic'
      if (!isBasicObstruction && isContinuousModeEnabled(action.globalState)) return { ...state }

      return {
        ...state,
        activeToolProps: {},
        activeTool: 'SELECT_TOOL',
      }
    })
    .addCase(DESELECT_OBJECTS, (state, action) => {
      return {
        ...state,
        activeToolProps: {},
        activeTool: action.payload.tool || 'SELECT_TOOL',
      }
    })
    .addMatcher(action => [ADD_OBJECT, ADD_PRODUCT, ADD_DIMENSION].includes(action.type), (state, action) => {
      if (isContinuousModeEnabled(action.globalState)) return { ...state }

      return {
        ...state,
        activeToolProps: {},
        activeTool: 'SELECT_TOOL',
      }
    })
    .addMatcher(action => [ADD_DOOR, ADD_UTILITY_BOX, ADD_COMFORT_POINT, ADD_COMFORT_ZONE, SELECT_OBJECTS, DELETE_SELECTED_OBJECTS].includes(action.type), (state, action) => {
      return {
        ...state,
        activeToolProps: {},
        activeTool: 'SELECT_TOOL',
      }
    })
})
