import { type Connect, connect, useDispatch, useSelector, useStore, type TypedUseSelectorHook } from 'react-redux'
import type { AppDispatch, AppStore, RootState } from '.'
import { createAsyncThunk, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'

export const useAppDispatch: () => ThunkDispatch<RootState, ApolloClient<NormalizedCacheObject>, UnknownAction> = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppStore: () => AppStore = useStore

export const appConnect: Connect<RootState> = connect

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  extra: ApolloClient<NormalizedCacheObject>,
}>()
