import { useFragment, useQuery } from '@apollo/client'
import { graphql } from '~/gql'
import { CFD_STATUS } from 'config/cfd'
import { useFacilityMatch } from 'config/routes'
import { CfdStatusFragment } from '~/gql/graphql'

const CFD_STATUS_FRAGMENT = graphql(`
  fragment CfdStatus on Version {
    cfd {
      id
      status
      type
      internalType
      URLs {
        id
      }
    }
  }
`)

const GET_CFD_STATUS_QUERY = graphql(`
  query GetCFDStatuses($versionId: ID!) {
    Version(id: $versionId) {
      id
      ...CfdStatus
    }
  }
`)

type CfdStatus = Exclude<Exclude<CfdStatusFragment['cfd'], null | undefined>[number], null>

const findStatus = (statusType: string) => (result: CfdStatus | null) => {
  return result?.status === statusType
}

/** CFDs that are over 730 days old will be deleted from s3 via lifecycle policy */
const isExpiredCFD = (result: CfdStatus) => {
  return (result.URLs?.length ?? 0) === 0 && result.status === 'COMPLETE'
}

const formatCfdStatusResults = (cfdStatuses: (CfdStatus | null)[]) => {
  const statuses = cfdStatuses.filter((result): result is CfdStatus => !!result)
  return {
    simulationResults: Object.fromEntries(
      statuses.map(({ internalType, ...rest }) => [internalType, rest])
    ),
    isFirstSimulation: cfdStatuses.every(result => !result || isExpiredCFD(result)),
    isSomeInProgress: cfdStatuses.some(findStatus(CFD_STATUS.IN_PROGRESS)),
    isSomeCanceled: cfdStatuses.some(findStatus(CFD_STATUS.CANCELED)),
    isSomeFailed: cfdStatuses.some(findStatus(CFD_STATUS.FAILED)),
    isAllComplete: statuses.length > 0 && statuses.every(findStatus(CFD_STATUS.COMPLETE)),
    isAllInProgress: statuses.every(findStatus(CFD_STATUS.IN_PROGRESS)),
    successfulSimulationTypes: Array.from(
      new Set(
        statuses
          .filter(result => result.status === CFD_STATUS.COMPLETE)
          .map(({ internalType }) => internalType)
      )
    ),
  }
}

export const useCFDStatus = () => {
  const versionId = useFacilityMatch().versionId ?? ''
  const { data, loading, error } = useQuery(GET_CFD_STATUS_QUERY, {
    variables: { versionId: versionId },
    pollInterval: 15000,
    skip: !versionId,
    fetchPolicy: 'network-only',
  })
  const cfdStatuses = data?.Version?.cfd as (CfdStatus | null)[]

  return {
    cfdStatus: cfdStatuses && formatCfdStatusResults(cfdStatuses),
    loading,
    error,
  }
}

export const useCFDStatusFragment = () => {
  const { versionId } = useFacilityMatch()
  const { complete, data } = useFragment({
    fragment: CFD_STATUS_FRAGMENT,
    from: {
      __typename: 'Version',
      id: versionId,
    },
  })
  if (!complete) return { complete, cfdStatus: undefined }
  const statuses = data!.cfd!.filter(status => status !== undefined)
  return { complete, cfdStatus: formatCfdStatusResults(statuses) }
}
