export type Edge = {
  p1: [number, number]
  p2: [number, number]
}

export const isPointInPolygon = ([x, y]: [number, number], edges: Edge[]) => {
  // https://observablehq.com/@tmcw/understanding-point-in-polygon
  let isInZone = false
  for (const { p1, p2 } of edges) {
    const [x1, y1] = p1
    const [x2, y2] = p2
    const isIntersection = y1 > y !== y2 > y && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1
    if (isIntersection) isInZone = !isInZone
  }
  return isInZone
}
