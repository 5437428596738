/* eslint-disable no-mixed-operators */
import { SnapLine } from './snapRegion'
import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import upperFirst from 'lodash-es/upperFirst'
import flatMap from 'lodash-es/flatMap'
import Facility from './facility'
import Camera from './camera'
import Units from './units'
import intersect from './polygonIntersection'
import { isTouchDevice, guid } from 'lib/utils'
import OBJECT_TYPES from 'config/objectTypes'
import Wall from './wall'

import * as THREE from 'three'

const directions = [
  new THREE.Vector3(0, 1, 0),
  new THREE.Vector3(0, -1, 0),
  new THREE.Vector3(1, 0, 0),
  new THREE.Vector3(-1, 0, 0),
  new THREE.Vector3(1, 1, 0),
  new THREE.Vector3(1, -1, 0),
  new THREE.Vector3(-1, 1, 0),
  new THREE.Vector3(-1, -1, 0),
]

class Util {
  // Works for points in object or array format
  static pointsAreEqual2D(p1, p2, threshold = 0.001) {
    if (!p1 || !p2) return false

    // object style
    if (p1.x !== undefined) {
      return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2) < threshold
    }

    // array style
    return Math.sqrt((p2[0] - p1[0]) ** 2 + (p2[1] - p1[1]) ** 2) < threshold
  }

  static pointsAreEqual3D(p1 = {}, p2 = {}, threshold = 0.001) {
    return (
      Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2 + (p2.z - p1.z) ** 2) <
      threshold
    )
  }

  static slopeFromPoints(firstPoint, secondPoint) {
    let point1 = firstPoint
    let point2 = secondPoint

    // Convert to object style point if necessary
    if (point1.x === undefined) {
      point1 = Util.arrayPointToObjectPoint(point1)
      point2 = Util.arrayPointToObjectPoint(point2)
    }

    let xChange = point2.x - point1.x
    let yChange = point2.y - point1.y

    // Horizontal
    if (Math.abs(xChange) < 0.00001) {
      xChange = 0.00001
    }

    // Vertical
    if (Math.abs(yChange) < 0.00001) {
      yChange = 0.00001
    }

    const slope = yChange / xChange

    return slope
  }

  static getPolylineMidPoint(polyline) {
    const midpoint1 = polyline[Math.floor(polyline.length / 2) - 1]
    const midpoint2 = polyline[Math.floor(polyline.length / 2)]
    const midpoint = {
      x: midpoint1[0] + (midpoint2[0] - midpoint1[0]) / 2,
      y: midpoint1[1] + (midpoint2[1] - midpoint1[1]) / 2,
    }

    return midpoint
  }

  static getLineSegmentLength(point1, point2) {
    const x = point1[0] - point2[0]
    const y = point1[1] - point2[1]
    return Math.sqrt(x ** 2 + y ** 2)
  }

  static getLineSegmentCenter(point1, point2) {
    let convertedToArrayPoint = false
    if (point1.x !== undefined) {
      point1 = [point1.x, point1.y]
      point2 = [point2.x, point2.y]
      convertedToArrayPoint = true
    }

    const x = point1[0] + (point2[0] - point1[0]) / 2
    const y = point1[1] + (point2[1] - point1[1]) / 2

    if (convertedToArrayPoint) {
      return new THREE.Vector3(x, y, 0)
    } else {
      return [x, y]
    }
  }

  static isPolygonValid(polygon) {
    if (!polygon || polygon.length < 3) {
      return false
    }

    if (polygon[0].x === undefined && polygon[0][0] !== undefined) {
      polygon = polygon.map(point => Util.arrayPointToObjectPoint(point))
    }

    const firstAndLastPointsMatch = Util.pointsAreEqual2D(
      polygon[0],
      polygon[polygon.length - 1]
    )
    const greaterThanZeroArea =
      Util.polygonArea(polygon) > Units.inchesToNative(0.1)

    return firstAndLastPointsMatch && greaterThanZeroArea
  }

  static isPolygonRectangle(polygon) {
    // Must be polygon
    if (!Util.isPolygonValid(polygon)) return false

    // Must have 4 sides
    if (polygon.length !== 5) return false

    // opposing sides must be equal
    const d1 = Util.distanceToLineSegment(
      polygon[0][0],
      polygon[0][1],
      polygon[1][0],
      polygon[1][1],
      polygon[2][0],
      polygon[2][1]
    )
    const d2 = Util.distanceToLineSegment(
      polygon[1][0],
      polygon[1][1],
      polygon[2][0],
      polygon[2][1],
      polygon[3][0],
      polygon[3][1]
    )
    const d3 = Util.distanceToLineSegment(
      polygon[2][0],
      polygon[2][1],
      polygon[3][0],
      polygon[3][1],
      polygon[4][0],
      polygon[4][1]
    )
    const d4 = Util.distanceToLineSegment(
      polygon[3][0],
      polygon[3][1],
      polygon[0][0],
      polygon[0][1],
      polygon[1][0],
      polygon[1][1]
    )

    if (d1 - d3 > Units.inchesToNative(1) || d2 - d4 > Units.inchesToNative(1))
      return false

    return true
  }

  // Gets the position between two vectors at a given distance
  static getPositionBetweenTwoVectors(vectorA, vectorB, length) {
    const startVector = new THREE.Vector3().copy(vectorA)
    const endVector = new THREE.Vector3().copy(vectorB)

    const direction = endVector
      .clone()
      .sub(startVector)
      .normalize()
      .multiplyScalar(length)
    return startVector.clone().add(direction)
  }

  // Gets the direction from the origin vector to the target vector
  static getVectorToVectorDirection(target, origin) {
    const direction = new THREE.Vector3().subVectors(target, origin)
    direction.set(direction.x, direction.y, 0).normalize()

    return direction
  }

  /*
    Adapted from `computeVertexNormals()` here:
    https://github.com/mrdoob/three.js/blob/dev/src/core/BufferGeometry.js

    Returns an array of triangles in the form {a, b, c} where a, b, and c are
    THREE.Vector3 objects.
  */
  static getTrianglesFromGeometry(geometry, debug = false) {
    let triangles

    if (geometry.isGeometry) {
      triangles = geometry.faces.map(
        face =>
          new THREE.Triangle(face.a.clone(), face.b.clone(), face.c.clone())
      )
    } else {
      const index = geometry.index
      const attributes = geometry.attributes
      const groups = geometry.groups
      const positionAttr = attributes.position
      const positionAttrCount = positionAttr.count

      triangles = []

      const pA = new THREE.Vector3()
      const pB = new THREE.Vector3()
      const pC = new THREE.Vector3()

      // indexed elements
      if (index) {
        const indices = index.array

        if (groups.length === 0) {
          geometry.addGroup(0, indices.length)
        }

        for (let j = 0, jl = groups.length; j < jl; ++j) {
          const group = groups[j]

          const start = group.start
          const count = group.count

          let iA, iB, iC

          for (let i = start, il = start + count; i < il; i += 3) {
            iA = indices[i + 0]
            iB = indices[i + 1]
            iC = indices[i + 2]

            pA.fromBufferAttribute(positionAttr, iA)
            pB.fromBufferAttribute(positionAttr, iB)
            pC.fromBufferAttribute(positionAttr, iC)

            triangles.push(
              new THREE.Triangle(pA.clone(), pB.clone(), pC.clone())
            )
          }
        }
      } else {
        // non-indexed elements (unconnected triangle soup)
        for (let i = 0; i < positionAttrCount; i += 3) {
          pA.fromBufferAttribute(positionAttr, i + 0)
          pB.fromBufferAttribute(positionAttr, i + 1)
          pC.fromBufferAttribute(positionAttr, i + 2)

          triangles.push(new THREE.Triangle(pA.clone(), pB.clone(), pC.clone()))
        }
      }
    }

    if (debug) {
      const points = flatMap(triangles, t => [t.a, t.b, t.c])
      Util.geometryDebug(
        points,
        true,
        5,
        'triangles',
        true,
        0,
        'getTrianglesFromGeometry'
      )
    }

    return triangles
  }

  static arrayPointToObjectPoint(arrayPoint) {
    return new THREE.Vector3(arrayPoint[0], arrayPoint[1], 0)
  }

  static objectPointToArrayPoint(objPoint) {
    return [objPoint.x, objPoint.y]
  }

  static geometryUnion(geometry1, geometry2) {
    const g1Positions = geometry1.getAttribute('position').array
    const g2Positions = geometry2.getAttribute('position').array
    const positions = new Float32Array(g1Positions.length + g2Positions.length)
    positions.set(g1Positions, 0)
    positions.set(g2Positions, g1Positions.length)

    const g1Normals = geometry1.getAttribute('normal').array
    const g2Normals = geometry2.getAttribute('normal').array
    const normals = new Float32Array(g1Normals.length + g2Normals.length)
    normals.set(g1Normals, 0)
    normals.set(g2Normals, g1Normals.length)

    const unionGeo = geometry1.clone()
    unionGeo.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    unionGeo.setAttribute('normal', new THREE.BufferAttribute(normals, 3))

    return unionGeo
  }

  // From: https://github.com/substack/point-in-polygon
  static isPointInPolygon(point, polygonPoints) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    polygonPoints = Util.toVec3Array(polygonPoints)

    const x = point.x
    const y = point.y

    let inside = false
    let i = 0
    for (
      let j = polygonPoints.length - 1;
      i < polygonPoints.length;
      j = i - 1
    ) {
      const xi = polygonPoints[i].x
      const yi = polygonPoints[i].y
      const xj = polygonPoints[j].x
      const yj = polygonPoints[j].y

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside

      i += 1
    }

    return inside
  }

  static isFacilityRectangle() {
    const wallWithRoof = Facility.current.getWalls().find(wall => wall.roofId)
    if (wallWithRoof) {
      const centerLinePoints = wallWithRoof.centerLinePoints
      return Util.isPolygonRectangle(centerLinePoints)
    }
    return false
  }

  static isPointInCircle(point, center, radius) {
    return (
      Math.pow(center.x - point.x, 2) + Math.pow(center.y - point.y, 2) <=
      radius * radius
    )
  }

  static isPointOnPolygonPerimeter(
    point,
    polygonPoints,
    threshold = Units.inchesToNative(4)
  ) {
    polygonPoints = Util.toVec3Array(polygonPoints)

    const overlapsPolygonPoint =
      polygonPoints.find(polyPoint =>
        Util.pointsAreEqual2D(point, polyPoint, threshold)
      ) !== undefined

    if (overlapsPolygonPoint) return true

    let smallestDistanceToSegment = 100000

    for (let i = 0; i < polygonPoints.length; i += 1) {
      const index1 = i
      const index2 = (i + 1) % polygonPoints.length
      const p1 = polygonPoints[index1]
      const p2 = polygonPoints[index2]
      const distance = Util.distanceToLineSegment(
        p1.x,
        p1.y,
        p2.x,
        p2.y,
        point.x,
        point.y
      )

      if (distance < smallestDistanceToSegment) {
        smallestDistanceToSegment = distance
      }
    }

    return smallestDistanceToSegment <= threshold
  }

  /*
    From: https://github.com/math-utils/area-polygon
  */
  static polygonArea(points = []) {
    let det = 0

    points = points.map(point => {
      if (!Array.isArray(point)) return point
      return {
        x: point[0],
        y: point[1],
      }
    })

    if (!Util.pointsAreEqual2D(points[0], points[points.length - 1]))
      points = points.concat(points[0])

    for (let i = 0; i < points.length - 1; i += 1)
      det += points[i].x * points[i + 1].y - points[i].y * points[i + 1].x

    return Math.abs(det) / 2
  }

  /*
    From: https://github.com/ayamflow/polygon-centroid
  */
  static polygonCentroid(points) {
    points = points.map(point => {
      // Convert to object style if necessary
      const finalPoint = !Array.isArray(point)
        ? {
            x: point.x,
            y: point.y,
            z: point.z,
          }
        : {
            x: point[0],
            y: point[1],
            z: 0,
          }

      // Ensure there is a z-coord
      finalPoint.z = finalPoint.z || 0

      return finalPoint
    })

    // Ensure last point is the first point repeated
    if (Util.pointsAreEqual3D(points[0], points[points.length - 1]))
      points.pop()

    const l = points.length

    return points.reduce((center, p, i) => {
      center.x += p.x
      center.y += p.y
      center.z += p.z

      if (i === l - 1) {
        center.x /= l
        center.y /= l
        center.z /= l
      }

      return center
    }, new THREE.Vector3(0, 0, 0))
  }

  static filterDuplicatePoints(list, distanceThreshold = 0) {
    const noDupsList = []

    for (let i = 0; i < list.length; i += 1) {
      const point = list[i]
      let alreadyHadIt = false
      for (let j = 0; j < noDupsList.length; j += 1) {
        const noDupsPoint = noDupsList[j]

        if (Util.pointsAreEqual2D(point, noDupsPoint, distanceThreshold)) {
          alreadyHadIt = true
          break
        }
      }

      if (!alreadyHadIt) {
        noDupsList.push(point)
      }
    }

    return noDupsList
  }

  // Given three colinear points p, q, r, the function checks if
  // point q lies on line segment 'pr'
  static isPointOnSegment(p, q, r) {
    const px = p.x.toPrecision(6)
    const qx = q.x.toPrecision(6)
    const rx = r.x.toPrecision(6)
    const py = p.y.toPrecision(6)
    const qy = q.y.toPrecision(6)
    const ry = r.y.toPrecision(6)

    if (
      qx <= Math.max(px, rx) &&
      qx >= Math.min(px, rx) &&
      qy <= Math.max(py, ry) &&
      qy >= Math.min(py, ry)
    )
      return true

    return false
  }

  // To find orientation of ordered triplet (p, q, r).
  // The function returns following values
  // 0 --> p, q and r are colinear
  // 1 --> Clockwise
  // 2 --> Counterclockwise
  static orientation(p, q, r) {
    // See http://www.geeksforgeeks.org/orientation-3-ordered-points/
    // for details of below formula.
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y)

    if (val === 0) return 0 // colinear

    return val > 0 ? 1 : 2 // clock or counterclock wise
  }

  // Just used by `lineSegmentsIntersectionPoint(...)`
  static _between(a, b, c) {
    const eps = 0.0000001
    return a - eps <= b && b <= c + eps
  }

  /*
    If the line segments intersect, the intersection point is
    returned in the format `{x, y}`; otherwise `false` is returned.
  */
  static lineSegmentsIntersectionPoint(
    line1Start,
    line1End,
    line2Start,
    line2End
  ) {
    const x1 = line1Start.x
    const y1 = line1Start.y
    const x2 = line1End.x
    const y2 = line1End.y
    const x3 = line2Start.x
    const y3 = line2Start.y
    const x4 = line2End.x
    const y4 = line2End.y

    var x =
      ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) /
      ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4))
    var y =
      ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) /
      ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4))
    if (isNaN(x) || isNaN(y)) {
      return false
    } else {
      if (x1 >= x2) {
        if (!Util._between(x2, x, x1)) {
          return false
        }
      } else {
        if (!Util._between(x1, x, x2)) {
          return false
        }
      }
      if (y1 >= y2) {
        if (!Util._between(y2, y, y1)) {
          return false
        }
      } else {
        if (!Util._between(y1, y, y2)) {
          return false
        }
      }
      if (x3 >= x4) {
        if (!Util._between(x4, x, x3)) {
          return false
        }
      } else {
        if (!Util._between(x3, x, x4)) {
          return false
        }
      }
      if (y3 >= y4) {
        if (!Util._between(y4, y, y3)) {
          return false
        }
      } else {
        if (!Util._between(y3, y, y4)) {
          return false
        }
      }
    }
    return { x: x, y: y }
  }

  // Based on: http://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
  // The main function that returns true if line segment 'p1q1'
  // and 'p2q2' intersect.
  static lineSegmentsIntersect(point1, point2, point3, point4) {
    let p1 = point1
    let q1 = point2
    let p2 = point3
    let q2 = point4

    // We have an array style point, convert
    if (p1.x === undefined && p1[0] !== undefined) {
      p1 = { x: point1[0], y: point1[1] }
      q1 = { x: point2[0], y: point2[1] }
      p2 = { x: point3[0], y: point3[1] }
      q2 = { x: point4[0], y: point4[1] }
    }

    // Find the four orientations needed for general and
    // special cases
    const o1 = Util.orientation(p1, q1, p2)
    const o2 = Util.orientation(p1, q1, q2)
    const o3 = Util.orientation(p2, q2, p1)
    const o4 = Util.orientation(p2, q2, q1)

    // General case
    if (o1 !== o2 && o3 !== o4) return true

    // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1
    if (o1 === 0 && Util.isPointOnSegment(p1, p2, q1)) return true

    // p1, q1 and p2 are colinear and q2 lies on segment p1q1
    if (o2 === 0 && Util.isPointOnSegment(p1, q2, q1)) return true

    // p2, q2 and p1 are colinear and p1 lies on segment p2q2
    if (o3 === 0 && Util.isPointOnSegment(p2, p1, q2)) return true

    // p2, q2 and q1 are colinear and q1 lies on segment p2q2
    if (o4 === 0 && Util.isPointOnSegment(p2, q1, q2)) return true

    return false // Doesn't fall in any of the above cases
  }

  /*
    Splits the given triangles into groups which are on the same plane
    and all touching one another.
  */
  static collectCoPlanarTriangleGroups(trianglePoints, dotThreshold = 0.0001) {
    const uniqueNormals = []
    const groups = []
    let groupCount = 0

    for (let i = 0; i < trianglePoints.length; i += 3) {
      const triangle = {
        p1: trianglePoints[i],
        p2: trianglePoints[i + 1],
        p3: trianglePoints[i + 2],
      }
      const surfaceNormal = Util.surfaceNormalForTriangle(triangle)

      const groupIndex = uniqueNormals.findIndex(
        normal => Math.abs(1 - normal.dot(surfaceNormal)) < dotThreshold
      )

      // Pre-existing was found
      if (groupIndex > -1) {
        groups[groupIndex].push(triangle)
      } else {
        // Create a new group
        groups[groupCount] = [triangle]
        uniqueNormals[groupCount] = surfaceNormal
        groupCount += 1
      }
    }

    const contiguousGroups = groups.reduce((array, group) => {
      return array.concat(Util.contiguousGroupsFromTriangles(group))
    }, [])

    return contiguousGroups
  }

  /*
    Split the given triangles into groups which all touch one another
  */
  static contiguousGroupsFromTriangles(triangles) {
    const contiguousGroups = []
    triangles.forEach(triangle => {
      const matchingGroup = contiguousGroups.find(group =>
        group.find(placedTriangle =>
          Util.trianglesTouch(triangle, placedTriangle)
        )
      )
      if (matchingGroup) {
        matchingGroup.push(triangle)
      } else {
        // Start new group
        contiguousGroups.push([triangle])
      }
    })

    return contiguousGroups
  }

  /*
    Returns true if the given triangles have at least
    one overlapping vertex.
  */
  static trianglesTouch(
    triangle1,
    triangle2,
    threshold = Units.inchesToNative(0.5)
  ) {
    for (let i = 1; i <= 3; i += 1) {
      for (let j = 1; j <= 3; j += 1) {
        if (
          Util.pointsAreEqual2D(
            triangle1[`p${i}`],
            triangle2[`p${j}`],
            threshold
          )
        ) {
          return true
        }
      }
    }

    return false
  }

  static surfaceNormalForTriangle(triangle) {
    const side1 = triangle.p2.clone().sub(triangle.p1)
    const side2 = triangle.p3.clone().sub(triangle.p1)
    const surfaceNormal = new THREE.Vector3()
    surfaceNormal.crossVectors(side1, side2).normalize()

    return surfaceNormal
  }

  /*
    Given a coplanar group of triangles (of the form {p1, p2, p3}) with 3d coordinates,
    find the polygon forming the 'silhouette' of the entire group.
  */
  static polygonForTriangleGroup(triangleGroup) {
    /*
      Algorithm overview:
        - Start with the rightmost point (and add to solution set)
        - Get all edges from this point
        - Follow the edge with the smallest angle to the positive x-axis, and also add it to the solution set
        - From the point reached, follow and add the edge with the smallest angle to the edge you came from
        - Repeat until you reach the original point

      Inspired by: https://stackoverflow.com/questions/1014293/2d-outline-algorithm-for-projected-3d-mesh
    */

    const connectedNodeList = Util.getTriangleGraph(triangleGroup)

    // All the nodes whose points will form the final silhouette polygon
    const solution = []

    let rightMostNode = connectedNodeList[0]

    for (let i = 1; i < connectedNodeList.length; i += 1) {
      if (connectedNodeList[i].point.x > rightMostNode.point.x) {
        rightMostNode = connectedNodeList[i]
      }
    }

    solution.push(rightMostNode)

    let currentNode = rightMostNode

    let count = 0
    const MAX_ITERATIONS = 10000

    // Follow edges on the silhouette of the group until we return
    // to the first, rightmost node.
    do {
      let smallestAngleNode
      let smallestAngle = Math.PI * 2

      for (let i = 0; i < currentNode.neighbors.length; i += 1) {
        const neighbor = currentNode.neighbors[i]
        const candidateEdge = neighbor.point.clone().sub(currentNode.point)
        let candidateEdgeAngle

        if (solution.length < 2) {
          // If we haven't followed any edges yet, candidateEdgeAngle is the angle
          // from the candidate edge to the positive x-axis
          candidateEdgeAngle = Util.angleToXAxis(candidateEdge)
        } else {
          // If we have already followed at least one edge, our candidateEdgeAngle is
          // the the angle between our candidate edge and the last edge we took.

          // Prevent backtracking to previous node
          if (
            Util.pointsAreEqual2D(
              neighbor.point,
              solution[solution.length - 2].point
            )
          ) {
            continue
          }

          const lastEdge = solution[solution.length - 2].point
            .clone()
            .sub(currentNode.point)
          const lastEdgeAngleToXAxis = Util.angleToXAxis(lastEdge)

          const candidateEdgeAngleToXAxis = Util.angleToXAxis(candidateEdge)
          candidateEdgeAngle = candidateEdgeAngleToXAxis - lastEdgeAngleToXAxis

          // Ensure the angle we find is that made by positive rotation only
          if (candidateEdgeAngle < 0) {
            candidateEdgeAngle += Math.PI * 2
          }
        }
        if (candidateEdgeAngle < smallestAngle) {
          smallestAngle = candidateEdgeAngle
          smallestAngleNode = neighbor
        }
      }

      solution.push(smallestAngleNode)
      currentNode = smallestAngleNode
      smallestAngle = Math.PI * 2
      count++
    } while (
      !Util.pointsAreEqual2D(
        get(currentNode, 'point'),
        get(rightMostNode, 'point')
      ) &&
      count < MAX_ITERATIONS
    )

    // This way if something goes wrong we can be notified rather than
    // getting stuck in an infinite loop.
    if (count >= MAX_ITERATIONS) {
      console.warn(
        `Triangle group silhouette discovery failed: followed ${count} edges without returning to first node.`
      )
      return []
    }

    const groupSilhouettePolygon = solution.map(node => node.point)

    return groupSilhouettePolygon
  }

  /*
    Using only the x and y components of the given vector (i.e. if it's a
    Vector3, z will be ignored), this computes the angle through which the
    given vector would have to rotate (clockwise) in order to be in line
    with the positive x-axis.
  */
  static angleToXAxis(vector) {
    if (vector.y >= 0) {
      return Math.acos(vector.x / Math.sqrt(vector.x ** 2 + vector.y ** 2))
    } else {
      return (
        Math.PI +
        Math.acos((vector.x * -1) / Math.sqrt(vector.x ** 2 + vector.y ** 2))
      )
    }
  }

  /*
    Given a group of triangles of the form {p1, p2, p3}, return
    a graph of node objects in the form: {point, neighbors}. Each
    triangle will have its own vertices connected in the graph, in
    addition to the vertices of any other triangles from the group
    whose vertices overlap. The graph is returned as a list of all
    its nodes.
  */
  static getTriangleGraph(triangleGroup) {
    // Form nodes and connect neighbors for each triangle,
    // forming a three node graph for each triangle, where
    // graph edges correspond to triangle edges.
    const connectedTriangles = triangleGroup.map(triangle => {
      const node1 = { point: triangle.p1, neighbors: [] }
      const node2 = { point: triangle.p2, neighbors: [] }
      const node3 = { point: triangle.p3, neighbors: [] }

      node1.neighbors.push(node2)
      node1.neighbors.push(node3)

      node2.neighbors.push(node1)
      node2.neighbors.push(node3)

      node3.neighbors.push(node2)
      node3.neighbors.push(node1)

      return { node1, node2, node3 }
    })

    // Share neighbors between nodes which overlap one another spatially
    for (let i = 0; i < connectedTriangles.length; i += 1) {
      const triangle1 = connectedTriangles[i]
      for (let j = i + 1; j < connectedTriangles.length; j += 1) {
        const triangle2 = connectedTriangles[j]
        const overlappingNodes = this.getOverlappingTriangleNodes(
          triangle1,
          triangle2
        )

        overlappingNodes.forEach(nodePair => {
          const mergedArray = Util.mergeArrays(
            nodePair.node1.neighbors,
            nodePair.node2.neighbors
          )
          nodePair.node1.neighbors = mergedArray
          nodePair.node2.neighbors = mergedArray.slice()
        })
      }
    }

    // Reduce array of connected triangles to an array of nodes
    const nodes = connectedTriangles.reduce((array, triangle) => {
      return array.concat([triangle.node1, triangle.node2, triangle.node3])
    }, [])

    // Filter out any nodes sharing the same spatial position
    const uniqueNodes = []
    do {
      const candidateNode = nodes.pop()
      const preExistingNode = nodes.find(node =>
        Util.pointsAreEqual2D(
          node.point,
          candidateNode.point,
          Units.inchesToNative(0.5)
        )
      )

      if (!preExistingNode) {
        uniqueNodes.push(candidateNode)
      }
    } while (nodes.length > 0)

    return uniqueNodes
  }

  static mergeArrays(a1, a2) {
    const mergedArray = a1.slice()

    a2.forEach(element => {
      if (
        !mergedArray.find(element2 =>
          Util.pointsAreEqual2D(
            element.point,
            element2.point,
            Units.inchesToNative(0.5)
          )
        )
      ) {
        mergedArray.push(element)
      }
    })

    return mergedArray
  }

  static getOverlappingTriangleNodes(triangle1, triangle2) {
    const overlappingNodes = []

    for (let i = 0; i < 3; i += 1) {
      const node1 = triangle1[`node${i + 1}`]
      for (let j = 0; j < 3; j += 1) {
        const node2 = triangle2[`node${j + 1}`]

        if (
          Util.pointsAreEqual2D(
            node1.point,
            node2.point,
            Units.inchesToNative(0.5)
          )
        ) {
          overlappingNodes.push({ node1, node2 })
        }
      }
    }

    return overlappingNodes
  }

  static boundingCubeForPoints(points) {
    let minX = Number.MAX_VALUE
    let maxX = -Number.MAX_VALUE
    let minY = Number.MAX_VALUE
    let maxY = -Number.MAX_VALUE
    let minZ = Number.MAX_VALUE
    let maxZ = -Number.MAX_VALUE

    for (let i = 0; i < points.length; i += 1) {
      const x = points[i].x
      const y = points[i].y
      const z = points[i].z
      minX = Math.min(minX, x)
      maxX = Math.max(maxX, x)
      minY = Math.min(minY, y)
      maxY = Math.max(maxY, y)
      minZ = Math.min(minZ, z)
      maxZ = Math.max(maxZ, z)
    }

    const width = maxX - minX
    const height = maxY - minY
    const depth = maxZ - minZ

    return { x: minX, y: minY, z: minZ, width, height, depth }
  }

  static boundingRectForPoints(points) {
    let minX = Number.MAX_VALUE
    let maxX = -Number.MAX_VALUE
    let minY = Number.MAX_VALUE
    let maxY = -Number.MAX_VALUE

    for (let i = 0; i < points.length; i += 1) {
      const x = points[i].x
      const y = points[i].y
      minX = Math.min(minX, x)
      maxX = Math.max(maxX, x)
      minY = Math.min(minY, y)
      maxY = Math.max(maxY, y)
    }

    const width = maxX - minX
    const height = maxY - minY

    return { x: minX, y: minY, width, height }
  }

  static rectangularPolygonFromBoundingRect(rect) {
    const UL = new THREE.Vector3(rect.x, rect.y, 0)
    const UR = new THREE.Vector3(rect.x + rect.width, rect.y, 0)
    const LR = new THREE.Vector3(rect.x + rect.width, rect.y + rect.height, 0)
    const LL = new THREE.Vector3(rect.x, rect.y + rect.height, 0)

    return [UL, LL, LR, UR, UL.clone()]
  }

  static boundingCircleForPolygon(points) {
    const centroid = Util.polygonCentroid(points)
    let greatestDistance = 0

    points.forEach(
      point =>
        (greatestDistance = Math.max(
          greatestDistance,
          centroid
            .clone()
            .sub(point)
            .length()
        ))
    )

    return { center: centroid, radius: greatestDistance }
  }

  static lineFromPoints(pointA, pointB) {
    let point1 = pointA
    let point2 = pointB

    // Convert to object style point if necessary
    if (point1.x === undefined) {
      point1 = Util.arrayPointToObjectPoint(point1)
      point2 = Util.arrayPointToObjectPoint(point2)
    }

    const slope = Util.slopeFromPoints(point1, point2)
    const yShift = point1.y - slope * point1.x

    return { slope, yShift }
  }

  static polygonToGeojson(poly) {
    if (poly.length > 0 && poly[0].x) {
      poly = poly.map(point => [point.x, point.y])
    }
    let coords = [poly]

    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: coords,
      },
    }
  }

  static polygonFromGeojson(geojson) {
    // We only use the first element of the coordinates array since
    // the geojson format deals with 'multipolygons', but ours will
    // always just be single polygons.
    return geojson.geometry.coordinates[0].map(
      point => new THREE.Vector3(point[0], point[1], 0)
    )
  }

  static getIntersectionPoint(slope1, yShift1, slope2, yShift2) {
    const intersectionX = (yShift2 - yShift1) / (slope1 - slope2)
    const intersectionY = slope2 * intersectionX + yShift2

    return new THREE.Vector3(intersectionX, intersectionY, 0)
  }

  static linesIntersect(slope1, yShift1, slope2, yShift2) {
    const intersection = SnapLine.getIntersectionPoint(
      slope1,
      yShift1,
      slope2,
      yShift2
    )

    return (
      !isNaN(intersection.x) &&
      isFinite(intersection.x) &&
      !isNaN(intersection.y) &&
      isFinite(intersection.y)
    )
  }

  /**
   * Calculate the square of the distance between a finite line segment and a point. This
   * version takes somewhat less convenient parameters than distanceToLineSegment.squared,
   * but is more efficient if you are calling it multiple times for the same line segment,
   * since you pass in some easily pre-calculated values for the segment.
   * @param {number} lx1 - x-coordinate of line segment's first point
   * @param {number} ly1 - y-coordinate of line segment's first point
   * @param {number} ldx - x-coordinate of the line segment's second point minus lx1
   * @param {number} ldy - y-coordinate of the line segment's second point minus ly1
   * @param {number} lineLengthSquared - must be ldx\*ldx + ldy\*ldy. Remember, this precalculation
   *    is for efficiency when calling this multiple times for the same line segment.
   * @param {number} px - x coordinate of point
   * @param {number} py - y coordinate of point
   */
  static _distanceSquaredToLineSegment2(
    lx1,
    ly1,
    ldx,
    ldy,
    lineLengthSquared,
    px,
    py
  ) {
    let t // t===0 at line pt 1 and t ===1 at line pt 2
    if (!lineLengthSquared) {
      // 0-length line segment. Any t will return same result
      t = 0
    } else {
      t = ((px - lx1) * ldx + (py - ly1) * ldy) / lineLengthSquared

      if (t < 0) t = 0
      else if (t > 1) t = 1
    }

    const lx = lx1 + t * ldx
    const ly = ly1 + t * ldy
    const dx = px - lx
    const dy = py - ly
    return dx * dx + dy * dy
  }

  /**
   * Calculate the square of the distance between a finite line segment and a point.
   * @param {number} lx1 - x-coordinate of line segment's first point
   * @param {number} ly1 - y-coordinate of line segment's first point
   * @param {number} lx2 - x-coordinate of the line segment's second point
   * @param {number} ly2 - y-coordinate of the line segment's second point
   * @param {number} px - x coordinate of point
   * @param {number} py - y coordinate of point
   */
  static _distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py) {
    const ldx = lx2 - lx1
    const ldy = ly2 - ly1
    const lineLengthSquared = ldx * ldx + ldy * ldy
    return Util._distanceSquaredToLineSegment2(
      lx1,
      ly1,
      ldx,
      ldy,
      lineLengthSquared,
      px,
      py
    )
  }

  /**
  * From: https://github.com/scottglz/distance-to-line-segment

  * Calculate the distance between a finite line segment and a point. Using distanceToLineSegment.squared can often be more efficient.
  * @param {number} lx1 - x-coordinate of line segment's first point
  * @param {number} ly1 - y-coordinate of line segment's first point
  * @param {number} lx2 - x-coordinate of the line segment's second point
  * @param {number} ly2 - y-coordinate of the line segment's second point
  * @param {number} px - x coordinate of point
  * @param {number} py - y coordinate of point
  */
  static distanceToLineSegment(lx1, ly1, lx2, ly2, px, py) {
    return Math.sqrt(
      Util._distanceSquaredToLineSegment(lx1, ly1, lx2, ly2, px, py)
    )
  }

  static getDebugCanvas(width, height, id = 0) {
    const canvasId = 'debug-canvas-' + id
    const containerId = 'debug-container-' + id
    const closeButtonId = 'debug-close-button-' + id
    let canvas = document.getElementById(canvasId)
    let container = document.getElementById(containerId)
    let closeButton = document.getElementById(closeButtonId)

    if (!canvas) {
      if (Util.debugCanvasCount === undefined) {
        Util.debugCanvasCount = 0
      } else {
        Util.debugCanvasCount += 1
      }

      container = document.createElement('div')
      canvas = document.createElement('canvas')
      document.body.append(container)
      canvas.id = canvasId
      canvas.width = width
      canvas.height = height
      const zIndexNumber = 1000 + Util.debugCanvasCount
      canvas.style.zIndex = `${zIndexNumber}`
      canvas.style.position = 'relative'
      canvas.style.left = 0
      canvas.style.top = 0
      canvas.style.right = 0
      canvas.style.bottom = 0
      canvas.style.backgroundColor = '#ddd'
      canvas.style.border = '1px solid black'
      canvas.style.visibility = 'visible'
      container.id = containerId
      container.style.width = `${width}`
      container.style.height = `${height}`
      container.style.zIndex = `${zIndexNumber}`
      container.style.position = 'absolute'
      container.style.left = `${200 + Util.debugCanvasCount * 10}px`
      container.style.top = `${Util.debugCanvasCount * 10}px`
      container.style.visibility = 'visible'

      closeButton = document.createElement('div')
      closeButton.id = closeButtonId
      closeButton.innerHTML = 'X'
      closeButton.style.position = 'absolute'
      closeButton.style.right = `10px`
      closeButton.style.top = `10px`
      closeButton.style.border = '1px solid black'
      closeButton.style.backgroundColor = '#f00'
      closeButton.style.zIndex = `${zIndexNumber + 1}`
      closeButton.style.cursor = 'pointer'
      closeButton.style.visibility = 'visible'
      closeButton.onclick = e => {
        canvas.style.visibility = 'hidden'
        container.style.visibility = 'hidden'
        closeButton.style.visibility = 'hidden'
        Util.debugCanvasCount -= 1
      }

      container.appendChild(closeButton)
      container.appendChild(canvas)
    } else {
      canvas.style.visibility = 'visible'
      container.style.visibility = 'visible'
      closeButton.style.visibility = 'visible'
    }

    return canvas
  }

  /*
    Draws a set of points interpreted as triangles, points, or a polygon.

    points: an array of object style points to draw
    center: whether the figure should be centered on the canvas or not
    scale: an amount to scale the points by when drawing
    style: 'triangle', 'polygon', and 'points' are supported at the moment ('points' is default)
    showCoords: whether to render the original coordinates next to each point
  */
  static geometryDebug(
    points,
    center,
    scale = 1,
    style = 'triangles',
    showCoords,
    canvasId = Util.debugCanvasCount || 0,
    name = ''
  ) {
    const originalPoints = points.slice()
    const canvasWidth = 1200
    const canvasHeight = Math.min(800, window.innerHeight)
    const centerCrossSize = 12
    const pointSize = 5

    const canvas = Util.getDebugCanvas(canvasWidth, canvasHeight, canvasId)

    // Reverse y-coordinate (three.js coordinate system is flipped with respect to html canvas)
    points = originalPoints.map(
      point => new THREE.Vector3(point.x, canvasHeight - point.y, 0)
    )

    if (center) {
      const rect = Util.boundingRectForPoints(points)
      const xShift = rect.x + rect.width / 2
      const yShift = rect.y + rect.height / 2
      points = points.map(
        point =>
          new THREE.Vector3(
            point.x * scale - xShift * scale + canvasWidth / 2,
            point.y * scale - yShift * scale + canvasHeight / 2,
            0
          )
      )
    }

    var ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, canvasWidth, canvasHeight)

    ctx.font = '30px Arial'
    ctx.fillText(name, 10, 40)

    ctx.strokeWidth = 1
    ctx.strokeStyle = '#fff'
    ctx.beginPath()
    ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
    ctx.lineTo(canvasWidth / 2 - centerCrossSize / 2, canvasHeight / 2)
    ctx.lineTo(canvasWidth / 2 + centerCrossSize / 2, canvasHeight / 2)
    ctx.moveTo(canvasWidth / 2, canvasHeight / 2)
    ctx.lineTo(canvasWidth / 2, canvasHeight / 2 - centerCrossSize / 2)
    ctx.lineTo(canvasWidth / 2, canvasHeight / 2 + centerCrossSize / 2)
    ctx.stroke()

    if (style === 'triangles') {
      ctx.strokeStyle = '#888'
      ctx.beginPath()

      points.forEach((point, i) => {
        if (i % 3 === 0) {
          if (i >= 3) {
            ctx.lineTo(points[i - 3].x, points[i - 3].y)
          }
          ctx.moveTo(points[i].x, points[i].y)
        } else {
          ctx.lineTo(points[i].x, points[i].y)
        }
      })

      ctx.stroke()
    } else if (style === 'polygon') {
      ctx.strokeStyle = '#888'
      ctx.beginPath()

      points.forEach((point, i) => {
        if (i === 0) {
          ctx.moveTo(points[i].x, points[i].y)
        } else {
          ctx.lineTo(points[i].x, points[i].y)
        }
      })

      ctx.stroke()
    }

    Util.drawPoints(ctx, points, originalPoints, pointSize, showCoords, scale)
  }

  static drawPoints(ctx, pointPositions, points, pointSize, showCoords, scale) {
    const placedPoints = []
    ctx.font = 'bold 12px Arial'
    pointPositions.forEach((point, i) => {
      const x = point.x - pointSize / 2
      const y = point.y - pointSize / 2
      ctx.fillStyle = '#ff0000'
      ctx.fillRect(x, y, pointSize, pointSize)

      // count pre-existing points so we can shift the y position of the coordinate string
      let preExistingPointCount = 0
      placedPoints.forEach(placedPoint => {
        if (
          point
            .clone()
            .sub(placedPoint)
            .length() < Units.inchesToNative(1 * scale)
        ) {
          preExistingPointCount += 1
        }
      })

      const indexString = `${i}`
      const coordinateString = `: (${points[i].x.toFixed(3)}, ${points[
        i
      ].y.toFixed(3)})`
      const string = indexString + (showCoords ? coordinateString : '')
      ctx.fillStyle = '#555'
      ctx.fillText(string, x + pointSize * 1.5, y + preExistingPointCount * 20)

      placedPoints.push(point)
    })
  }

  // This could probably also take a rotation param
  static snapLinesForOrthogonalAxesAtPoint(
    point,
    range = Units.feetToNative(1)
  ) {
    const pointAbovePoint = { x: point.x, y: point.y - 1 }
    const verticalSnapLine = new SnapLine(point, pointAbovePoint, range)

    const pointLeftOfPoint = { x: point.x - 1, y: point.y }
    const horizontalSnapLine = new SnapLine(point, pointLeftOfPoint, range)

    return [horizontalSnapLine, verticalSnapLine]
  }

  // See https://hansmuller-webkit.blogspot.com/2013/02/where-is-mouse.html
  static canvasMousePos(event, canvas) {
    const box = canvas.getBoundingClientRect()
    const x = event.clientX - box.left
    const y = event.clientY - box.top

    return { x, y }
  }

  static vec3ToScreenPoint(vector, camera, canvasWidth, canvasHeight) {
    // Make a copy since .project(...) will transform the vector
    const vectorCopy = vector.clone()

    const widthHalf = 0.5 * canvasWidth
    const heightHalf = 0.5 * canvasHeight

    vectorCopy.project(camera)

    vectorCopy.x = vectorCopy.x * widthHalf + widthHalf
    vectorCopy.y = -(vectorCopy.y * heightHalf) + heightHalf

    return {
      x: vectorCopy.x,
      y: vectorCopy.y,
    }
  }

  static isFirstPointRepeated(
    polygon,
    distanceThreshold = Units.inchesToNative(0.01)
  ) {
    return Util.pointsAreEqual2D(
      polygon[0],
      polygon[polygon.length - 1],
      distanceThreshold
    )
  }

  static getCounterClockwisePoints(points) {
    if (points.length > 2 && !Util.pointsAreCounterClockwise(points)) {
      return points.slice().reverse()
    }

    return points.slice()
  }

  // See: https://stackoverflow.com/questions/1165647/how-to-determine-if-a-list-of-polygon-points-are-in-clockwise-order
  static pointsAreCounterClockwise(points) {
    if (points.length < 3) {
      console.error(
        "'polygon' of fewer than three points passed to pointsAreCounterClockwise: ",
        points
      )
      return false
    }

    points = Util.toVec3Array(points)
    if (!points || !points.length) return false

    if (!Util.isFirstPointRepeated(points)) {
      if (points[0] instanceof THREE.Vector3) points.push(points[0].clone())
    }

    const edgeCount = points.length - 1

    let sum = 0

    for (let i = 0; i < edgeCount; i += 1) {
      sum += (points[i + 1].x - points[i].x) * (points[i + 1].y + points[i].y)
    }

    return sum < 0
  }

  // See: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  static guid() {
    return guid()
  }

  static underscoredIndentifierToCamelCase(identifier, capitalizeFirstLetter) {
    const parts = identifier.toLowerCase().split('_')
    let camelCase = capitalizeFirstLetter
      ? Util.capitalizeFirstLetter(parts[0])
      : parts[0]

    for (let i = 1; i < parts.length; i += 1) {
      camelCase += Util.capitalizeFirstLetter(parts[i])
    }

    return camelCase
  }

  // TODO: Remove duplicate logic
  static capitalizeFirstLetter = upperFirst

  /*
    Given two objects to compare (previousObj and currentObj), a type (e.g. 'wall'),
    and a function to use for checking object equality, this function will give back
    an object with three properties: added, removed, and updated; each is an array
    containing the objects which were either added, removed, or updated in currentObj.
  */
  static simpleObjectDiff(previousObj, currentObj, equalityFunc = isEqual) {
    if (currentObj === undefined) return { added: [], removed: [], updated: [] }

    const added = []
    const removed = []
    const updated = []

    // Find added and updated values
    Object.entries(currentObj).forEach(curEntry => {
      if (curEntry[1]) {
        const key = curEntry[0]
        const value = curEntry[1]
        const previousValue =
          previousObj === undefined ? undefined : previousObj[key]

        if (previousValue === undefined) {
          added.push(value)
        } else if (!equalityFunc(previousValue, value)) {
          updated.push(value)
        }
      }
    })

    // Find removed values
    if (previousObj !== undefined) {
      Object.entries(previousObj).forEach(prevEntry => {
        if (prevEntry[1]) {
          const previousKey = prevEntry[0]
          const previousValue = prevEntry[1]
          const currentValue = currentObj[previousKey]

          if (currentValue === undefined) {
            removed.push(previousValue)
          }
        }
      })
    }

    return { added, removed, updated }
  }

  /*
    Returns a THREE.Vector3 which at the center of the given line, but offset either
    to the left or right side by the given distance. The coordinates are local, the
    idea being that it would be used to offset a child node from a parent line.
  */
  static getPointOffsetFromLineCenter(
    lineStart,
    lineEnd,
    offsetDistance,
    leftSide
  ) {
    const orthoVec = Util.getLineSegmentNormal(lineStart, lineEnd, leftSide)

    const position = orthoVec.clone().multiplyScalar(offsetDistance)

    return position
  }

  static getLineSegmentNormal(lineStart, lineEnd, leftSide) {
    const lineVec = lineEnd.clone().sub(lineStart)

    const rotation = (Math.PI / 2) * (leftSide ? 1 : -1)
    const normal = lineVec
      .clone()
      .normalize()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), rotation)

    return normal
  }

  /**
   * @template {THREE.Vector3 | THREE.Vector2} T
   *
   * @arg {T} point
   * @arg {T[]} points
   */
  static getClosestPoint(point, points) {
    let closestPoint = points[0]
    let closestDistance = point.distanceTo(closestPoint)
    for (let i = 1; i < points.length; ++i) {
      const p = points[i]
      const distance = point.distanceTo(p)
      if (distance < closestDistance) {
        closestPoint = p
        closestDistance = distance
      }
    }

    return closestPoint
  }

  static getFurthestTwoPoints(points) {
    let greatestDistance = 0
    let furthestPoints = []
    for (let i = 0; i < points.length - 1; i++) {
      const p1 = points[i]
      if (!(p1 instanceof THREE.Vector2) && !(p1 instanceof THREE.Vector3))
        continue
      for (let j = i + 1; j < points.length; j++) {
        const p2 = points[j]
        const distance = p1
          .clone()
          .sub(p2)
          .length()
        if (distance >= greatestDistance) {
          furthestPoints = [p1, p2]
          greatestDistance = distance
        }
      }
    }

    return furthestPoints
  }

  /*
    Gives a measure of how similar two polygons are ranging from
    zero (no overlap), to one (fully overlapping, identical polygons).
  */
  static polygonSimilarity(poly1, poly2) {
    if (!poly1) return 0
    if (!poly2) return 0

    poly1 = Util.toVec3Array(poly1)
    poly2 = Util.toVec3Array(poly2)

    if (Util.pointsAreEqual2D(poly1[0], poly1[poly1.length - 1])) poly1.pop()
    if (Util.pointsAreEqual2D(poly2[0], poly2[poly2.length - 1])) poly2.pop()

    let intersectionArray = intersect(poly1, poly2)
    let similarity = 0

    if (intersectionArray.length > 0) {
      const intersection = Util.toVec3Array(intersectionArray[0])
      const poly1Area = Util.polygonArea(poly1)
      const poly2Area = Util.polygonArea(poly2)
      const intersectionArea = Util.polygonArea(intersection)

      let similarity1 = intersectionArea / poly1Area
      similarity1 = similarity1 > 1 ? 1 / similarity1 : similarity1

      let similarity2 = intersectionArea / poly2Area
      similarity2 = similarity2 > 1 ? 1 / similarity2 : similarity2

      similarity = Math.min(similarity1, similarity2)
    }

    return similarity
  }

  static projectPolygonOnPlane(polygon, direction, planePoint, planeNormal) {
    return polygon.map(point => {
      const result = Util.rayPlaneIntersectionPoint(
        point,
        direction,
        planePoint,
        planeNormal
      )
      return result
    })
  }

  static rayPlaneIntersectionPoint(rayStart, rayDir, planePoint, planeNormal) {
    const rayP0 = rayStart.clone()
    const rayP1 = rayStart.clone().add(rayDir)
    const p1ToP0 = rayP1.clone().sub(rayP0)
    const scalar =
      planeNormal.dot(planePoint.clone().sub(rayStart)) /
      planeNormal.dot(p1ToP0)

    return rayStart.clone().addScaledVector(p1ToP0, scalar)
  }

  static findSharedPolygonEdges(polygons) {
    let sharedEdges = []

    for (let i = 0; i < polygons.length - 1; i += 1) {
      const poly1 = polygons[i]

      for (let j = i + 1; j < polygons.length; j += 1) {
        const poly2 = polygons[j]

        sharedEdges = sharedEdges.concat(
          Util.findOverlappingPolygonEdges(poly1, poly2)
        )
      }
    }

    return sharedEdges
  }

  static findOverlappingPolygonEdges(
    polygon1,
    polygon2,
    threshold = Units.inchesToNative(1)
  ) {
    polygon1 = Util.getCounterClockwisePoints(polygon1)
    polygon2 = Util.getCounterClockwisePoints(polygon2).reverse() // clockwise

    const overlappingSegments = []

    for (let i = 0; i < polygon1.length - 1; i += 1) {
      const poly1EdgePoint1 = polygon1[i]
      const poly1EdgePoint2 = polygon1[i + 1]

      for (let j = 0; j < polygon2.length - 1; j += 1) {
        const poly2EdgePoint1 = polygon2[j]
        const poly2EdgePoint2 = polygon2[j + 1]

        const point1Diff = poly1EdgePoint1
          .clone()
          .sub(poly2EdgePoint1)
          .length()
        const point2Diff = poly1EdgePoint2
          .clone()
          .sub(poly2EdgePoint2)
          .length()

        if (point1Diff + point2Diff < threshold) {
          overlappingSegments.push({
            point1: poly1EdgePoint1,
            point2: poly1EdgePoint2,
          })
          break
        }
      }
    }

    return overlappingSegments
  }

  /*
    Takes array style points (e.g. [5, 5, 5]) or object style
    points: {x, y, z}, and maps them to THREE.Vector3 objects.

    If given an empty array or an array of THREE.Vector3 objects,
    a copy of the input will be returned.
  */
  /**
   * @arg {(THREE.Vector3Like | THREE.Vector3Tuple)[]} points
   * @returns {THREE.Vector3[]}
   */
  static toVec3Array(points) {
    if (!points) {
      return points
    } else if (points.length === 0) {
      return [] // This way we always return a copy.
    }

    if (!(points[0] instanceof THREE.Vector3)) {
      // Object style
      if (!isNaN(points[0].x)) {
        const z = points[0].z !== undefined ? points[0].z : 0
        return points.map(point => new THREE.Vector3(point.x, point.y, z))
      } else if (!isNaN(points[0][0])) {
        // Array style
        const z = points[0][2] !== undefined ? points[0][2] : 0
        return points.map(point => new THREE.Vector3(point[0], point[1], z))
      } else {
        // Unknown format
        return points.slice()
      }
    } else {
      return points.slice()
    }
  }

  /*
    Takes array style points (e.g. [5, 5]) or object style
    points: {x, y}, and maps them to THREE.Vector2 objects.

    If given an empty array or an array of THREE.Vector2 objects,
    a copy of the input will be returned.
  */
  static toVec2Array(points) {
    if (!points) {
      return points
    } else if (points.length === 0) {
      return [] // This way we always return a copy.
    }

    if (!(points[0] instanceof THREE.Vector2)) {
      // Object style
      if (!isNaN(points[0].x)) {
        return points.map(point => new THREE.Vector2(point.x, point.y))
      } else if (!isNaN(points[0][0])) {
        // Array style
        return points.map(point => new THREE.Vector2(point[0], point[1]))
      } else {
        // Unknown format
        return points.slice()
      }
    } else {
      return points.slice()
    }
  }

  static findChildrenWithType(root, type) {
    return Util.filterChildNodes(
      root,
      node => node.userData.objectType === type
    )
  }

  static filterChildNodes(root, filterFunc) {
    const matchingNodes = []
    Util.filterChildNodesAux(root, filterFunc, matchingNodes)

    return matchingNodes
  }

  static filterChildNodesAux(node, filterFunc, matchingNodes) {
    const childMatches = node.children.filter(filterFunc)

    childMatches.forEach(matchingChild => {
      matchingNodes.push(matchingChild)
      Util.filterChildNodesAux(matchingChild, filterFunc, matchingNodes)
    })
  }

  /**
   * Depth first walk of a three.js node and all of its children.
   * The passed in function is called at each node, and the current
   * node is passed in as the sole argument.
   *
   * @param {THREE.Object3D} node
   * @param {(node: THREE.Object3D) => void} func
   */
  static walkNodes(node, func) {
    func(node)

    for (let i = 0; i < node.children.length; i += 1) {
      Util.walkNodes(node.children[i], func)
    }
  }

  static getSceneGraphRootFromNode(node) {
    do {
      if (node.userData.objectType === OBJECT_TYPES.SCENE) {
        return node
      } else {
        node = node.parent
      }
    } while (node)

    // Something went wrong since we couldn't find the 'scene'
    // node, but this node is still a root if we got to this point.
    return node
  }

  /**
   * Checks whether `maybeDescendent` is a descendent of `maybeAncestor`
   * or if `maybeDescendent` is equal to `maybeAncestor`.
   *
   * @param {THREE.Object3D} maybeDescendent
   * @param {THREE.Object3D} maybeAncestor
   * @returns {boolean}
   */
  static nodeDescendsFromNode(maybeDescendent, maybeAncestor) {
    do {
      if (maybeDescendent === maybeAncestor) {
        return true
      } else {
        maybeDescendent = maybeDescendent.parent
      }
    } while (maybeDescendent)

    return false
  }

  /*
    Computes a bounding box which is the union of the geometry
    belonging to the passed in node and all descendent meshes.
    This exists because the Three.Box3().setFromObject() method
    is broken—possibly having to do with THREE.Group nodes.
  */
  static computeCompositeBoundingBox(obj3d, options = {}) {
    const allMeshes = []

    Util.walkNodes(obj3d, node => {
      if (node.geometry) {
        const notExcluded =
          !options.excludedTypes ||
          !options.excludedTypes.includes(node.userData.objectType)

        if (notExcluded) allMeshes.push(node)
      }
    })

    let totalBox = new THREE.Box3()
    allMeshes.forEach(mesh => {
      mesh.updateMatrixWorld()
      const geometryCopy = mesh.geometry.clone()
      geometryCopy.applyMatrix4(mesh.matrixWorld)
      geometryCopy.computeBoundingBox()
      totalBox = totalBox.union(geometryCopy.boundingBox)
    })

    return totalBox
  }

  static remove(array, object) {
    array.splice(
      array.findIndex(element => element === object),
      1
    )
  }

  // https://stackoverflow.com/a/27709336
  static getGradientColor(startColor, endColor, percent) {
    // strip the leading # if it's there
    startColor = startColor.replace(/^\s*#|\s*$/g, '')
    endColor = endColor.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (startColor.length === 3) {
      startColor = startColor.replace(/(.)/g, '$1$1')
    }

    if (endColor.length === 3) {
      endColor = endColor.replace(/(.)/g, '$1$1')
    }

    // get colors
    let startRed = parseInt(startColor.substr(0, 2), 16),
      startGreen = parseInt(startColor.substr(2, 2), 16),
      startBlue = parseInt(startColor.substr(4, 2), 16)

    let endRed = parseInt(endColor.substr(0, 2), 16),
      endGreen = parseInt(endColor.substr(2, 2), 16),
      endBlue = parseInt(endColor.substr(4, 2), 16)

    // calculate new color
    let diffRed = endRed - startRed
    let diffGreen = endGreen - startGreen
    let diffBlue = endBlue - startBlue

    diffRed = (diffRed * percent + startRed).toString(16).split('.')[0]
    diffGreen = (diffGreen * percent + startGreen).toString(16).split('.')[0]
    diffBlue = (diffBlue * percent + startBlue).toString(16).split('.')[0]

    // ensure 2 digits by color
    if (diffRed.length === 1) diffRed = '0' + diffRed
    if (diffGreen.length === 1) diffGreen = '0' + diffGreen
    if (diffBlue.length === 1) diffBlue = '0' + diffBlue

    return '#' + diffRed + diffGreen + diffBlue
  }

  static isPositionOverObjectTypeWithId(pos, objectType, id, facility) {
    const position = new THREE.Vector3(pos.x, pos.y, 400)
    const direction = new THREE.Vector3(0, 0, -1).normalize()
    const intersections = facility.current.measureObjectsInDirectionFromPoint(
      direction,
      position,
      { includedTypes: objectType }
    )

    const object = intersections.find(intersect => intersect.wrapper.id === id)

    return !!object
  }

  static getObjectsUnderPositionByType(pos, objectTypes, facility) {
    const position = new THREE.Vector3(pos.x, pos.y, 400)
    const direction = new THREE.Vector3(0, 0, -1).normalize()
    const intersections = facility.current.measureObjectsInDirectionFromPoint(
      direction,
      position,
      { includedTypes: objectTypes }
    )

    return intersections
  }

  static nearestPointOnSegment(segPoint1, segPoint2, point) {
    const length = Math.pow(segPoint2.distanceTo(segPoint1), 2)
    if (length === 0.0) return point
    const p2MinusP1 = segPoint2.clone().sub(segPoint1)
    const t = Math.max(
      0,
      Math.min(
        1,
        point
          .clone()
          .sub(segPoint1)
          .dot(p2MinusP1) / length
      )
    )
    const projection = segPoint1.clone().add(p2MinusP1.multiplyScalar(t))
    return projection
  }

  // Takes a wall segment ID and returns the direction
  // 'away' from the outside of the wall
  static getDirectionAwayFromOutsideWall(segmentId) {
    const segment = Facility.current
      .getWallSegments()
      .find(seg => seg.id === segmentId)

    if (!segment) return new THREE.Vector3()

    const insetPoints = Util.toVec3Array(segment.insetPoints)
    const centerInsetPoint = insetPoints[0].add(insetPoints[1])
    const outsetPoints = Util.toVec3Array(segment.outsetPoints)
    const centerOutsetPoint = outsetPoints[0].add(outsetPoints[1])

    return centerOutsetPoint.sub(centerInsetPoint)
  }

  // Takes a wall segment ID and returns an array of offset points
  // based on the outset and inset points of the wall segment
  static getOffsetByOutsetAndInsetPoints(segmentId) {
    const segment = Facility.current
      .getWallSegments()
      .find(seg => seg.id === segmentId)

    if (!segment) return new THREE.Vector3()

    const insetPoints = Util.toVec3Array(segment.insetPoints)
    const outsetPoints = Util.toVec3Array(segment.outsetPoints)

    return [
      insetPoints[0].sub(outsetPoints[0]),
      insetPoints[1].sub(outsetPoints[1]),
    ]
  }

  // Takes a delta and returns the longer of the two distances (x or y)
  static getDominateDeltaAxisDistance(delta) {
    if (!delta) return

    return Math.abs(delta.x) > Math.abs(delta.y) ? delta.x : delta.y
  }

  // Takes a delta and an origin and target vector
  // Returns true if the dominate delta axis matches the direction
  // of the origin to target direction
  static isDirectionToward2dVector(delta, origin, target) {
    if (!delta || !origin || !target) return

    const direction = new THREE.Vector3().subVectors(origin, target).normalize()

    if (Math.abs(delta.x) > Math.abs(delta.y)) {
      if (Math.sign(direction.x) === Math.sign(delta.x)) {
        return true
      }
    } else {
      if (Math.sign(direction.y) === Math.sign(delta.y)) {
        return true
      }
    }

    return false
  }

  // Takes a start and end vector and returns
  // the points to complete a rectangle
  static getBoxPositions(startPos, endPos) {
    let leftX
    let rightX
    let topY
    let bottomY
    if (startPos.x < endPos.x) {
      leftX = startPos.x
      rightX = endPos.x
    } else {
      rightX = startPos.x
      leftX = endPos.x
    }

    if (startPos.y < endPos.y) {
      topY = startPos.y
      bottomY = endPos.y
    } else {
      bottomY = startPos.y
      topY = endPos.y
    }

    return [
      { x: leftX, y: topY, z: 0 },
      { x: rightX, y: topY, z: 0 },
      { x: rightX, y: bottomY, z: 0 },
      { x: leftX, y: bottomY, z: 0 },
      { x: leftX, y: topY, z: 0 },
    ]
  }

  // Takes a product ID and returns the wall intersect
  // points in the four main cardinal directions
  static getProductToWallAndFanCenterIntersects(productId) {
    const product = Facility.current
      .getProducts()
      .find(product => product.id === productId)

    const directions = [
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, -1, 0),
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(-1, 0, 0),
    ]

    const toWallOptions = {
      measureFrom: Facility.CENTER,
      measureTo: Facility.SURFACE,
      includedTypes: [OBJECT_TYPES.WALL, OBJECT_TYPES.FAN_COLLISION_CUBE],
      xyOnly: true,
    }

    // Get a path to all walls
    const intersections = []
    directions.forEach(direction => {
      const intersects = Facility.current.measureObjectsInDirectionFromObject(
        direction,
        product.obj3d,
        toWallOptions
      )
      // sort intersects by distance
      intersects.sort((a, b) => a.distance - b.distance)
      // Only keep objects between the fan and the first wall found
      const intersectsToFirstWall = intersects.slice(
        0,
        intersects.findIndex(int => int.wrapper instanceof Wall) + 1
      )

      if (intersectsToFirstWall.length) {
        if (intersectsToFirstWall.length === 1) {
          intersections.push({ wall: intersectsToFirstWall[0], direction })
        } else if (intersectsToFirstWall.length > 0) {
          intersections.push({
            wall: intersectsToFirstWall[intersectsToFirstWall.length - 1],
            nearestObject: intersectsToFirstWall[0],
            direction,
          })
        }
      }
    })
    const wallPoints = []
    intersections.forEach(intersect => {
      const point = intersect.wall.vector.add(product.obj3d.position)
      wallPoints.push({
        distance: intersect.wall.distance,
        nearestObject: intersect.nearestObject,
        x: Units.nativeToInches(point.x),
        y: Units.nativeToInches(point.y),
        z: Units.nativeToInches(point.z),
        direction: intersect.direction,
      })
    })

    // Get the 2 closest walls, one vertical (y) and one horizontal (x)
    const XYWalls = [wallPoints.slice(0, 2), wallPoints.slice(2, 4)]

    if (!XYWalls[0].length || !XYWalls[1].length) return

    const closestWallPoints = XYWalls.map(wallArray => {
      const nearestWall =
        get(wallArray, '[0].distance') > get(wallArray, '[1].distance')
          ? wallArray[1]
          : wallArray[0]
      // If there were any fans on the path to the wall, return the fan path instead
      if (nearestWall && nearestWall.nearestObject) {
        const fanPoint = nearestWall.nearestObject.vector.add(
          product.obj3d.position
        )
        // Add 2 inches in the direction of the object to reach the center of the
        // productCenterCube, which is 4" long. NOTE: If any other objects are added
        // that need to be measured to, this function can be refactored to use
        // 'measureTo: Facility.CENTER' in measureObjectsInDirectionFromObject.
        // This would require multiple intersection measurements, but would
        // calculate the object center dynamically
        return {
          x: Units.nativeToInches(fanPoint.x) + nearestWall.direction.x * 2,
          y: Units.nativeToInches(fanPoint.y) + nearestWall.direction.y * 2,
          z: Units.nativeToInches(fanPoint.z) + nearestWall.direction.z * 2,
        }
      } else {
        return nearestWall
      }
    })

    // If mounted, don't keep the measurement to the attached wall
    if (product.isMounted) {
      const mountedWall =
        closestWallPoints[0].distance < closestWallPoints[1].distance ? 0 : 1
      closestWallPoints.splice(mountedWall, 1)
    }
    return {
      productPosition: {
        x: Units.nativeToInches(product.obj3d.position.x),
        y: Units.nativeToInches(product.obj3d.position.y),
        z: Units.nativeToInches(product.obj3d.position.z),
      },
      wallPoints: closestWallPoints,
    }
  }

  static getDistanceFromFloor(facility, position) {
    const floorPosition = new THREE.Vector3(position.x, position.y, 0)
    const positiveZ = new THREE.Vector3(0, 0, 1)
    const options = {
      includedTypes: [OBJECT_TYPES.ROOF],
      measureTo: Facility.SURFACE,
    }

    const hits = facility.measureObjectsInDirectionFromPoint(
      positiveZ,
      floorPosition,
      options
    )

    let floorToCeilDistance = 0

    if (hits.length > 0) floorToCeilDistance = hits[0].distance - 0.1
    else return floorToCeilDistance

    return floorToCeilDistance
  }

  // Takes an object3D and return its length, width, and height in inches
  static getObjectDimensionInInches(object3D, recursive = false) {
    if (!object3D) return

    const clone = object3D.clone()
    clone.rotation.set(0, 0, 0)
    if (!recursive) clone.children = []

    const box = new THREE.Box3().setFromObject(clone)

    return {
      height: Units.nativeToInches(box.max.z - box.min.z),
      width: Units.nativeToInches(box.max.x - box.min.x),
      length: Units.nativeToInches(box.max.y - box.min.y),
    }
  }

  static getHeightOfConnectedExteriorWall(startPoint, endPoint) {
    const exteriorWalls = Facility.current
      .getWallSegments()
      .filter(seg => seg.layerKey === 'EXTERIOR_WALLS')

    let height = 0
    for (let i = 0; i < exteriorWalls.length; i++) {
      const exteriorCLP = exteriorWalls[i].getCenterLinePoints()
      const exteriorStart = Util.arrayPointToObjectPoint(exteriorCLP[0])
      const exteriorEnd = Util.arrayPointToObjectPoint(exteriorCLP[1])
      if (
        Util.isPointOnSegment(exteriorStart, startPoint, exteriorEnd) ||
        Util.isPointOnSegment(exteriorStart, endPoint, exteriorEnd)
      ) {
        height = height
          ? Math.min(height, exteriorWalls[i].height)
          : exteriorWalls[i].height
      }
    }

    // If no height found try to manually find an exterior wall
    if (!height) {
      const facility = Facility.current
      const positiveZ = new THREE.Vector3(0, 0, 1)
      const startIntersects = facility.measureObjectsInDirectionFromPoint(
        positiveZ,
        startPoint,
        { includedTypes: [OBJECT_TYPES.WALL] }
      )
      const endIntersects = facility.measureObjectsInDirectionFromPoint(
        positiveZ,
        endPoint,
        { includedTypes: [OBJECT_TYPES.WALL] }
      )
      const foundExtWalls = startIntersects
        .concat(endIntersects)
        .filter(intersect => intersect.wrapper.layerKey === 'EXTERIOR_WALLS')

      // If exterior walls are found, use shortest wall height
      if (foundExtWalls.length) {
        const sortedExtWalls = sortBy(foundExtWalls, 'distance')
        return sortedExtWalls[0].distance
      }
    }

    return height
  }

  // Takes a start and end vector of a line and the number of desired points
  // Returns the evenly spaced vectors along the line based on point count
  static getEvenlySpacedPointsOnLine(startPos, endPos, pointCount) {
    const distance = startPos.distanceTo(endPos)
    const spacing = distance / Math.ceil(pointCount)
    const points = []

    for (let i = 0; i <= pointCount; i++) {
      const distance = spacing * i
      points.push(Util.getPositionBetweenTwoVectors(startPos, endPos, distance))
    }

    return points
  }

  // Takes an object3D, count of positions to find, the distance to
  // space them and if the spacing should be horiztonal or vertical.
  // Returns an array of Vector3 positions that are over a wall
  static getObjectSpacingOnWall(
    object,
    count = 1,
    distance = 36,
    isHorizontal = false,
    isInverted = false
  ) {
    const objectPosition = get(object, 'position')
    if (!objectPosition) return []

    const newPositions = []
    const nativeDistance = Units.inchesToNative(distance)
    const currentPos = new THREE.Vector3().copy(objectPosition)
    const bbox = new THREE.Box3().setFromObject(object)
    const objectOffset = isHorizontal
      ? Math.abs(bbox.max.x - bbox.min.x)
      : Math.abs(bbox.max.y - bbox.min.y)
    const totalDistance = nativeDistance + objectOffset

    for (let i = 0; i < count; i++) {
      if (isHorizontal) {
        if (isInverted) {
          currentPos.x -= totalDistance
        } else {
          currentPos.x += totalDistance
        }
      } else {
        if (isInverted) {
          currentPos.y += totalDistance
        } else {
          currentPos.y -= totalDistance
        }
      }
      newPositions.push(new THREE.Vector3().copy(currentPos))
    }

    // We only want positions that are over a wall
    const validPositions = newPositions.filter(pos => {
      const walls = Util.getObjectsUnderPositionByType(
        pos,
        [OBJECT_TYPES.WALL],
        Facility
      )

      // Test far edge position to prevent objects hanging off wall ends
      const edgePos = new THREE.Vector3().copy(pos)
      if (isHorizontal) {
        if (isInverted) {
          edgePos.x -= objectOffset / 2
        } else {
          edgePos.x += objectOffset / 2
        }
      } else {
        if (isInverted) {
          edgePos.y += objectOffset / 2
        } else {
          edgePos.y -= objectOffset / 2
        }
      }

      const edgeWalls = Util.getObjectsUnderPositionByType(
        edgePos,
        [OBJECT_TYPES.WALL],
        Facility
      )

      return walls.length && edgeWalls.length
    })

    return validPositions
  }

  // Takes a type (horizontal vertical) and an array of products
  // Returns updated products centered between walls based on type
  static getUpdatedCenteredProducts(type, products) {
    // If no products do nothing
    if (!products.length) return []

    const updatedProducts = []

    // Find centered positions for each selected products
    products.forEach(selectedProduct => {
      let model
      let product = Facility.current
        .getProducts()
        .find(prod => prod.id === selectedProduct.id)

      // If no product was matched in state use the current props
      if (!product) {
        model = selectedProduct
      }

      // Set the test position under the product just above the floor
      const position = new THREE.Vector3()
      if (get(product, 'obj3d.position')) {
        position.copy(product.obj3d.position)
      } else {
        position.copy(model.position)
      }
      position.z = 1

      const newPosition = Util.getPositionBetweenWalls(position, type)
      if (newPosition) {
        const newModel = model || product.toModel()
        const modelPosition = {
          x: Units.nativeToInches(newPosition.x),
          y: Units.nativeToInches(newPosition.y),
          z: Units.nativeToInches(newPosition.z),
        }

        newModel.position = modelPosition
        updatedProducts.push(newModel)
      }
    })

    return updatedProducts
  }

  // Takes a type (horizontal vertical) and an array of products
  // Returns updated product positions centered as a group between walls
  static getUpdatedCenteredProductGroup(type, products) {
    // If no products do nothing
    if (!products.length) return []

    const updatedProducts = []

    // Create a box from all of the products
    const container = new THREE.Object3D()
    products.forEach(selectedProduct => {
      const product = Facility.current
        .getProducts()
        .find(prod => prod.id === selectedProduct.id)
      container.add(product.obj3d.clone())
    })
    const box = new THREE.Box3().setFromObject(container)

    // Get the center of the box
    const x = (box.max.x + box.min.x) / 2
    const y = (box.max.y + box.min.y) / 2
    const z = get(products, '[0].obj3d.position.z', 0)
    const groupPosition = new THREE.Vector3(x, y, z)

    // Get the centered position of the group
    const centerPosition = Util.getPositionBetweenWalls(groupPosition, type)

    // Get the delta needed to move each product
    const delta = centerPosition.sub(groupPosition)

    // Add the delta to each product's position and update it
    products.forEach(selectedProduct => {
      const product = Facility.current
        .getProducts()
        .find(prod => prod.id === selectedProduct.id)
      const newModel = product.toModel()
      const newPosition = product.obj3d.position.add(delta)
      const modelPosition = {
        x: Units.nativeToInches(newPosition.x),
        y: Units.nativeToInches(newPosition.y),
        z: Units.nativeToInches(newPosition.z),
      }

      newModel.position = modelPosition
      updatedProducts.push(newModel)
    })

    return updatedProducts
  }

  // Takes a positon and a type (horizontal vertical)
  // Returns the center position between two found walls
  static getPositionBetweenWalls(position, type) {
    const directions = []
    if (type === 'horizontal') {
      directions.push(new THREE.Vector3(1, 0, 0))
      directions.push(new THREE.Vector3(-1, 0, 0))
    } else {
      directions.push(new THREE.Vector3(0, 1, 0))
      directions.push(new THREE.Vector3(0, -1, 0))
    }

    const toWallOptions = {
      measureFrom: Facility.CENTER,
      measureTo: Facility.SURFACE,
      includedTypes: [OBJECT_TYPES.WALL],
      xyOnly: true,
    }

    // Find a wall in each direction
    const wallPoints = []
    directions.forEach(direction => {
      const wallMeasurements = Facility.current.measureObjectsInDirectionFromPoint(
        direction,
        position,
        toWallOptions
      )

      if (wallMeasurements.length) wallPoints.push(wallMeasurements[0])
    })

    // If two walls were found center product between them
    if (wallPoints.length === 2) {
      const point1 = position.clone().add(wallPoints[0].vector)
      const point2 = position.clone().add(wallPoints[1].vector)
      const distance = point1.distanceTo(point2) / 2
      const centerPosition = Util.getPositionBetweenTwoVectors(
        point1,
        point2,
        distance
      )

      return centerPosition
    }

    return null
  }

  // Takes an arry of positions and returns true if all are over the facility
  static isPositionsOverFacility(positions = []) {
    if (!positions.length) return false

    // Get all exterior walls
    const walls = Facility.current
      .getWalls()
      .filter(wall => wall.layerKey === 'EXTERIOR_WALLS')

    // If no exterior walls return false
    if (!walls || !walls.length) return false

    const wallPoints = []
    walls.forEach(wall => {
      const points = wall.outsetPoints.map(point => [
        point[0] + wall.obj3d.position.x,
        point[1] + wall.obj3d.position.y,
      ])
      wallPoints.push(points)
    })

    // Account for the possibility of multiple exterior regions
    let isInsideWall = false
    wallPoints.forEach(wall => {
      let hasPositionOutsideThisWall = false
      positions.forEach(p => {
        // Set the position above the floor since we only care about the x and y axis
        const pos = new THREE.Vector3().copy(p)
        pos.z = 1

        if (!Util.isPointInPolygon(p, wall)) hasPositionOutsideThisWall = true
      })

      if (!isInsideWall && !hasPositionOutsideThisWall) isInsideWall = true
    })

    return isInsideWall
  }

  static containsExteriorWalls() {
    // Get all exterior walls
    const walls = Facility.current
      .getWalls()
      .filter(wall => wall.layerKey === 'EXTERIOR_WALLS')

    // If no exterior walls return false
    if (!walls || !walls.length) return false

    return true
  }

  static isSurroundingFacility(positions = []) {
    if (!positions.length) return false

    // Get all exterior walls
    const walls = Facility.current
      .getWalls()
      .filter(wall => wall.layerKey === 'EXTERIOR_WALLS')

    // If no exterior walls return false
    if (!walls || !walls.length) return false

    const wallPoints = []
    walls.forEach(wall => {
      const points = wall.outsetPoints.map(
        point =>
          new THREE.Vector3(
            point[0] + wall.obj3d.position.x,
            point[1] + wall.obj3d.position.y,
            0
          )
      )
      wallPoints.push(points)
    })

    const newPositions = positions.map(p => [p.x, p.y])

    // Account for the possibility of multiple exterior regions
    let containsFacility = false
    wallPoints.forEach(wall => {
      wall.forEach(p => {
        if (Util.isPointInPolygon(p, newPositions)) {
          containsFacility = true
        }
      })
    })

    return containsFacility
  }

  // Takes an arry of positions and returns true if all are outside the facility
  static isPositionsOutsideFacility(positions = []) {
    if (!positions.length) return false

    // Get all exterior walls
    const walls = Facility.current
      .getWalls()
      .filter(wall => wall.layerKey === 'EXTERIOR_WALLS')

    // If no exterior walls return true
    if (!walls || !walls.length) return true

    const wallPoints = []
    walls.forEach(wall => {
      const points = wall.outsetPoints.map(point => [
        point[0] + wall.obj3d.position.x,
        point[1] + wall.obj3d.position.y,
      ])
      wallPoints.push(points)
    })

    // Account for the possibility of multiple exterior regions
    let isInsideWall = false
    wallPoints.forEach(wall => {
      // let hasPositionInsideThisWall = false;
      positions.forEach(p => {
        // Set the position above the floor since we only care about the x and y axis
        const pos = new THREE.Vector3().copy(p)
        pos.z = 1

        if (Util.isPointInPolygon(p, wall)) isInsideWall = true
      })
    })

    return !isInsideWall
  }

  static getCameraPosition() {
    return new THREE.Vector3(
      Camera.current.position.x,
      Camera.current.position.y,
      0
    )
  }

  static linesAreCollinear2D(line1, line2, tolerance = 0) {
    if (!(line1.point1 && line1.point2 && line2.point1 && line2.point2))
      return false
    return (
      this.pointsAreCollinear(
        line1.point1,
        line1.point2,
        line2.point1,
        tolerance
      ) &&
      this.pointsAreCollinear(
        line1.point1,
        line1.point2,
        line2.point2,
        tolerance
      )
    )
  }

  // If the area of a triangle formed by 3 points is 0, the points are collinear
  static pointsAreCollinear(point1, point2, point3, tolerance = 0) {
    const area =
      point1.x * (point2.y - point3.y) +
      point2.x * (point3.y - point1.y) +
      point3.x * (point1.y - point2.y)
    return area >= -tolerance && area <= tolerance
  }

  // Takes a fan center position and fan size and checks 8 cardinal directions for intersecting walls or roofs
  // Returns true if fan is intersecting a wall
  static checkFoilWallCollisions(position, size) {
    if (!position || !size) return
    let fanPosition = position
    if (!(position instanceof THREE.Vector3)) {
      fanPosition = new THREE.Vector3(position.x, position.y, position.z)
    }

    const foilLength = Units.inchesToNative(size / 2)

    // Get intersections with walls or roofs
    const wallIntersections = directions.map(dir => {
      const options = {
        measureFrom: Facility.CENTER,
        measureTo: Facility.SURFACE,
        includedTypes: [OBJECT_TYPES.WALL, OBJECT_TYPES.ROOF],
      }
      const hits = Facility.current
        .measureObjectsInDirectionFromPoint(dir, fanPosition, options)
        .sort((a, b) => a.distance - b.distance)
      return hits[0]
    }).filter(it => !!it)
    wallIntersections.sort((a, b) => a.distance - b.distance)

    for (const intersection of wallIntersections) {
      const intersectionDistance = intersection.distance

      if (intersectionDistance && intersectionDistance < foilLength) {
        return intersection.wrapper ?? {}
      }
    }
    return undefined
  }
}

export default Util
