import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Canvas, RootState, useFrame, useThree } from '@react-three/fiber'
import { CameraControls, OrthographicCamera, PerspectiveCamera } from '@react-three/drei'
import { DrawingCanvas } from './DrawingCanvas'
import create from 'zustand'
import CameraControlsEnum from 'camera-controls'
import { useAppSelector, useAppDispatch } from "~/store/hooks";
import store, { RootState as AppRootState } from '~/store'
import { Box3, Object3D, OrthographicCamera as OrthographicCamera3, PerspectiveCamera as PerspectiveCamera3, Vector3 } from 'three'
import RenderLoop from './lib/renderLoop'
import Camera from './lib/camera'
import { set2DPosition, set2DZoom, set3DPosition, set3DRotation, fitFinished } from '~/store/camera'
import Facility from './lib/facility'
import { FloatingElementBridge } from './FloatingElementBridge'
import Mousetrap from 'mousetrap'
import classes from './withDrawingCanvas.module.css'
import { MountingStructures } from './MountingStructures'
import { Lights } from '~/components/DrawingCanvas/Lights'
import { Grid } from '~/components/DrawingCanvas/Grid'
import { Doors } from './Doors'
import { ToolManager, ToolManagerRef } from './ToolManager'
import { BackgroundImage } from '~/components/DrawingCanvas/BackgroundImage'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { DimensionLabels } from './DimensionLabels'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'

export function withThree(Component: React.ComponentType) {
  return (props: any) => {
    const raycaster = useThree((s) => s.raycaster)
    const getState = useThree((s) => s.get)
    const { setPan, setCursor } = useDrawingCanvasStore(({ setPan, setCursor}) => ({ setPan, setCursor }))
    const {onPointerOut, onPointerOver} = useCursor()
    useFrame((state, delta) => {
      RenderLoop.update(delta, getCanvasState(state))
    })
    return <Component {...props} raycaster={raycaster} setPan={setPan} setCursor={setCursor} onPointerOut={onPointerOut} onPointerOver={onPointerOver} getState={getState} />
  }
}

export function getCanvasState(state: RootState) {
  return {
    camera: state.camera,
    width: state.size.width,
    height: state.size.height,
  }
}

interface DrawingCanvasStore {
  pan: boolean
  cursor: string
  spacePan: boolean
  setPan(pan: boolean): void
  setCursor(cursor: string): void
  setSpacePan(spacePan: boolean): void
}

// use zustand here instead of redux because it lets us short circuit React
// when needed for 3D performance reasons
const useDrawingCanvasStore = create<DrawingCanvasStore>  ((set, get) => ({
  pan: true,
  spacePan: false,
  cursor: 'pointer',
  setPan(pan: boolean) {
    set(state => ({ ...state, pan }))
  },
  setCursor(cursor: string) {
    set(state => ({ ...state, cursor }))
  },
  setSpacePan(spacePan: boolean) {
    set(state => ({ ...state, spacePan }))
  },
}))

function useSelectorEffect<T>(selector: (store: AppRootState) => T, callback: (selected: T) => void, deps?: React.DependencyList) {
  useEffect(() => {
    let lastSelected = selector(store.getState())
    return store.subscribe(() => {
      const nextSelected = selector(store.getState())
      if (lastSelected != nextSelected) {
        callback(nextSelected)
      }
      lastSelected = nextSelected
    })
  }, deps)
}

const workBox = new Box3()
// like threejs Box3#expandByObject but requires bounding boxes to be finite
// this way we can still fit the facility to the screen even when there are
// infinitely sized objects in it
function expandByObject(box: Box3, object: Object3D) {
  // Computes the world-axis-aligned bounding box of an object (including its children),
  // accounting for both the object's, and children's, world transforms
  object.updateWorldMatrix(false, false)
  // @ts-expect-error threejs-style typecheck
  const geometry = object.geometry
  if (geometry !== undefined) {
    // @ts-expect-error threejs-style typecheck
    if (object.boundingBox !== undefined) {
      // object-level bounding box
      // @ts-expect-error threejs-style typecheck
      if (object.boundingBox === null) {
        // @ts-expect-error threejs-style typecheck
        object.computeBoundingBox()
      }
      // @ts-expect-error threejs-style typecheck
      workBox.copy(object.boundingBox)
    } else {
      // geometry-level bounding box
      if ( geometry.boundingBox === null ) {
        geometry.computeBoundingBox()
      }
      workBox.copy(geometry.boundingBox)
    }
    workBox.applyMatrix4(object.matrixWorld)
    if (isFinite(workBox.min.x)) {
      box.union(workBox)
    }
  }

  const children = object.children
  for (let i = 0, l = children.length; i < l; i++) {
    expandByObject(box, children[i])
  }
}

const PerspControls = ({ camera }: { camera: PerspectiveCamera3 }) => {
  const dispatch = useAppDispatch()
  const controls = useRef<CameraControlsEnum | null>(null)
  const spacePan = useDrawingCanvasStore(({ spacePan }) => spacePan)

  useEffect(() => {
    const { three } = store.getState().camera

    const it = controls.current!

    it.updateCameraUp()
    it.setPosition(...three.position.location)
    it.setTarget(...three.position.target)
    it.azimuthAngle = three.rotation.azimuth
    it.polarAngle = three.rotation.polar
    requestAnimationFrame(() => {
      it.setTarget(...three.position.target)
    })

    const callback = () => {
      dispatch(set3DRotation({ polar: it.polarAngle, azimuth: it.azimuthAngle, passive: true }))
      const vec3 = new Vector3()
      it.getPosition(vec3)
      const tar3 = new Vector3()
      it.getTarget(tar3)
      const off = new Vector3()
      it.getFocalOffset(off)
      dispatch(set3DPosition({ location: vec3.toArray(), target: tar3.toArray(), passive: true }))
    }
    it.addEventListener('rest', callback)
    return () => {
      it.removeEventListener('rest', callback)
    }
  }, [camera])
  useSelectorEffect((store) => store.camera.three, (three) => {
    if (!three.rotation.passive) {
      controls.current!.rotateTo(three.rotation.azimuth, three.rotation.polar, true)
    }
    if (!three.position.passive) {
      controls.current!.moveTo(...three.position.location, true)
    }
  })

  return (
    <CameraControls
      mouseButtons={{
        left: spacePan ? CameraControlsEnum.ACTION.TRUCK : CameraControlsEnum.ACTION.NONE,
        middle: CameraControlsEnum.ACTION.TRUCK,
        right: CameraControlsEnum.ACTION.ROTATE,
        wheel: CameraControlsEnum.ACTION.DOLLY,
      }}
      touches={{
        one: CameraControlsEnum.ACTION.TOUCH_TRUCK,
        two: CameraControlsEnum.ACTION.TOUCH_DOLLY_TRUCK,
        three: CameraControlsEnum.ACTION.TOUCH_ROTATE,
      }}
      makeDefault
      minPolarAngle={0}
      maxPolarAngle={0.5 * Math.PI}
      camera={camera}
      draggingSmoothTime={0.05}
      smoothTime={0.125}
      ref={some => {
        if (some) {
          some.setBoundary(new Box3(new Vector3(-5_000, -5_000, 0), new Vector3(5_000, 5_000, 0)))
        }
        controls.current = some
      }}
      verticalDragToForward={true}
      polarRotateSpeed={0.5}
      azimuthRotateSpeed={0.5}
      dollySpeed={0.5}
      dollyToCursor={true}
      truckSpeed={1.5}
      maxDistance={3000}
      minDistance={5}
    />
  )
}

const PerspCamera = React.forwardRef<PerspectiveCamera3>((_props, ref) => {
  const [camera, setCamera] = useState<PerspectiveCamera3 | null>(null)
  useImperativeHandle(ref, () => camera!, [camera])

  return (
    <>
      <PerspectiveCamera makeDefault fov={45} ref={setCamera} near={0.01} far={4000} up={[0, 0, 1]}/>
      {camera && <PerspControls camera={camera}/>}
    </>
  )
})

const OrthoControls = ({ camera }: { camera: OrthographicCamera3 }) => {
  const dispatch = useAppDispatch()
  const [controls, setControls] = useState<CameraControlsEnum | null>(null)
  const spacePan = useDrawingCanvasStore(({ spacePan }) => spacePan)

  useEffect(() => {
    if (!controls) {
      return
    }

    const { two } = store.getState().camera

    const it = controls!

    it.zoomTo(two.zoom.factor)
    it.setLookAt(...two.position.location, 2000, ...two.position.target, 0)
    it.azimuthAngle = 0
    it.polarAngle = 0

    const callback = () => {
      const vec3 = new Vector3()
      it.getPosition(vec3)
      const [x, y] = vec3
      const tar3 = new Vector3()
      it.getTarget(tar3)
      const [tx, ty] = tar3
      dispatch(set2DPosition({ location: [x, y], target: [tx, ty], passive: true }))
      dispatch(set2DZoom({ factor: it.camera.zoom, passive: true }))
    }
    it.addEventListener('rest', callback)
    return () => {
      it.removeEventListener('rest', callback)
    }
  }, [controls])
  useSelectorEffect((store) => store.camera.two, (two) => {
    if (!controls) {
      return
    }
    if (!two.zoom.passive) {
      controls.zoomTo(two.zoom.factor, true)
    }
    if (!two.position.passive) {
      controls.setLookAt(...two.position.location, 2000, ...two.position.target, 0, true)
    }
    const box = new Box3()
    expandByObject(box, Facility.current.obj3d)
    if (two.fit && !box.isEmpty()) {
      controls.fitToBox((Facility as any).current.obj3d, true, {
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 1,
        paddingBottom: 1,
      })
      dispatch(fitFinished())
    }
  }, [controls])
  useEffect(() => {
    if (!controls) {
      return
    }
    const box = new Box3()
    expandByObject(box, Facility.current.obj3d)
    if (store.getState().camera.two.fit && !box.isEmpty()) {
      controls.fitToBox(box, false, {
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 1,
        paddingBottom: 1,
      })
      dispatch(fitFinished())
    }
  }, [controls])

  return (
    <CameraControls
      mouseButtons={{
        left: spacePan ? CameraControlsEnum.ACTION.TRUCK : CameraControlsEnum.ACTION.NONE,
        middle: CameraControlsEnum.ACTION.TRUCK,
        right: CameraControlsEnum.ACTION.NONE,
        wheel: CameraControlsEnum.ACTION.ZOOM,
      }}
      touches={{
        one: CameraControlsEnum.ACTION.TOUCH_TRUCK,
        two: CameraControlsEnum.ACTION.TOUCH_ZOOM_TRUCK,
        three: CameraControlsEnum.ACTION.NONE,
      }}
      makeDefault
      camera={camera}
      draggingSmoothTime={0}
      polarAngle={0}
      azimuthAngle={0}
      maxPolarAngle={0}
      maxAzimuthAngle={0}
      minAzimuthAngle={0}
      minPolarAngle={0}
      maxZoom={50.0}
      minZoom={0.25}
      smoothTime={0.125}
      ref={setControls}
      verticalDragToForward={true}
      dollyToCursor={true}
    />
  )
}

const OrthoCamera = React.forwardRef<OrthographicCamera3>((_props, ref) => {
  const [camera, setCamera] = useState<OrthographicCamera3 | null>(null)
  useImperativeHandle(ref, () => camera!, [camera])

  return (
    <>
      <OrthographicCamera makeDefault ref={setCamera} near={0} far={4000} up={[0, 0, 1]}/>
      {camera && <OrthoControls camera={camera}/>}
    </>
  )
})

function useSpacePan() {
  const setSpacePan = useDrawingCanvasStore(({ setSpacePan }) => setSpacePan)

  useEffect(() => {
    Mousetrap.bind('space', () => { setSpacePan(true) }, 'keydown')
    Mousetrap.bind('space', () => { setSpacePan(false) }, 'keyup')
    return () => {
      Mousetrap.unbind('space', 'keydown')
      Mousetrap.unbind('space', 'keyup')
    }
  }, [])
}

export function withCanvas(Component: React.ComponentType) {
  return (props: any) => {
    const {
      configure: { handleRerenderOnFacilityLoad },
    } = useCFDUploadsContext()
    const is3D = useAppSelector((store: AppRootState) => store.camera.is3D)

    const canvas = useRef<DrawingCanvas>()
    const toolManager = useRef<ToolManagerRef>(null)

    // use these callbacks in order to let the CameraControls mutate without
    // React reactivity interfering with that
    const orthoCam = useRef<OrthographicCamera3>(null)
    const perspCam = useRef<PerspectiveCamera3>(null)

    const cursor = useDrawingCanvasStore(({ cursor }) => cursor)
    const spacePan = useDrawingCanvasStore(({ spacePan }) => spacePan)
    useEffect(() => {
      Object.defineProperty(Camera, 'current', {
        configurable: true,
        get() {
          return (is3D ? perspCam.current : orthoCam.current) ?? {
            get type() {
              return is3D ? 'PerspectiveCamera' : 'OrthographicCamera'
            }
          }
        },
      })
      return () => { delete Camera.current }
    })
    useSpacePan()

    return (
      <Canvas
        onMouseMove={event => { if (!toolManager.current?.onMouseMove?.(event)) canvas.current?.handleMouseMove(event) }}
        onMouseDown={event => {
          const element = document.elementFromPoint(event.clientX, event.clientY)
          if (!spacePan && element instanceof HTMLCanvasElement) {
            if (!toolManager.current?.onMouseDown?.(event)) {
              canvas.current?.handleMouseDown(event)
            }
          }
        }}
        onMouseUp={event => { if (!toolManager.current?.onMouseUp?.(event)) canvas.current?.handleMouseUp(event) }}
        onTouchMove={event => canvas.current?.handleTouchMove(event)}
        onTouchStart={event => canvas.current?.handleTouchDown(event)}
        onTouchEnd={event => canvas.current?.handleTouchUp(event)}
        onDoubleClick={event => canvas.current?.handleDoubleClick(event)}
        onContextMenu={event => canvas.current?.handleContextMenu(event)}
        style={spacePan ? {} : {cursor}}
        className={spacePan ? classes.spacePan : ''}
        id="drawing-canvas"
        gl={{preserveDrawingBuffer: true, logarithmicDepthBuffer: true}}
      >
        <Lights/>
        {!is3D ? <OrthoCamera ref={orthoCam}/> : undefined}
        {is3D ? <PerspCamera ref={perspCam}/> : undefined }
        <Component
          {...props}
          instantiate={(it: DrawingCanvas) => {
            canvas.current = it
            handleRerenderOnFacilityLoad()
          }}
        />
        <FloatingElementBridge/>
        <MountingStructures/>
        <Grid onPointerMove={e => {
          if (canvas.current) canvas.current.projectedMousePos = e.intersections[0].point
        }}/>
        <Doors/>
        <ToolManager ref={toolManager}/>
        <BackgroundImage/>
        <Grid/>
        <DimensionLabels/>
      </Canvas>
    )
  };
}
