export const LOAD_FACILITY = 'LOAD_FACILITY'
export const RESET_FACILITY = 'RESET_FACILITY'
export const ADD_OBJECT = 'ADD_OBJECT'
export const UPDATE_OBJECT = 'UPDATE_OBJECT'
export const UPDATE_OBJECTS = 'UPDATE_OBJECTS'
export const UPDATE_OBJECTS_PASSIVELY = 'UPDATE_OBJECTS_PASSIVELY'
export const FORCE_UPDATE_OBJECT = 'FORCE_UPDATE_OBJECT'
export const UPDATE_WALL = 'UPDATE_WALL'
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT'
export const UPDATE_SEGMENTS = 'UPDATE_SEGMENTS'
export const DELETE_OBJECTS = 'DELETE_OBJECTS'
export const DUPLICATE_OBJECTS = 'DUPLICATE_OBJECTS'
export const ADD_GRID = 'ADD_GRID'
export const REMOVE_GRID = 'REMOVE_GRID'
export const DUPLICATE_GRID = 'DUPLICATE_GRID'
export const ADD_ROOF = 'ADD_ROOF'
export const UPDATE_ROOF = 'UPDATE_ROOF'
export const UPDATE_ROOF_STRUCTURE = 'UPDATE_ROOF_STRUCTURE'
export const ADD_ROOF_SECTION = 'ADD_ROOF_SECTION'
export const UPDATE_ROOF_SECTION = 'UPDATE_ROOF_SECTION'
export const ADD_MOUNTING_BEAM = 'ADD_MOUNTING_BEAM'
export const REMOVE_MOUNTING_BEAM = 'REMOVE_MOUNTING_BEAM'
export const UPDATE_MOUNTING_BEAM = 'UPDATE_MOUNTING_BEAM'
export const ADD_COLUMN_LINE = 'ADD_COLUMN_LINE'
export const REMOVE_COLUMN_LINE = 'REMOVE_COLUMN_LINE'
export const UPDATE_COLUMN_LINE = 'UPDATE_COLUMN_LINE'
export const DELETE_ROOF_SECTION = 'DELETE_ROOF_SECTION'
export const REVERT_ROOF_SECTIONS = 'REVERT_ROOF_SECTIONS'
export const ADD_ELEVATION_POINT = 'ADD_ELEVATION_POINT'
export const UPDATE_ELEVATION_POINT = 'UPDATE_ELEVATION_POINT'
export const ADD_ELEVATION_LINE = 'ADD_ELEVATION_LINE'
export const UPDATE_ELEVATION_LINE = 'UPDATE_ELEVATION_LINE'
export const REMOVE_ELEVATION_LINE_HEIGHT = 'REMOVE_ELEVATION_LINE_HEIGHT'
export const ADD_DOOR = 'ADD_DOOR'
export const UPDATE_DOOR = 'UPDATE_DOOR'
export const DISTRIBUTE_DOORS = 'DISTRIBUTE_DOORS'
export const ADD_UTILITY_BOX = 'ADD_UTILITY_BOX'
export const UPDATE_UTILITY_BOX = 'UPDATE_UTILITY_BOX'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const DISTRIBUTE_PRODUCTS = 'DISTRIBUTE_PRODUCTS'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const ADD_OBSTRUCTION = 'ADD_OBSTRUCTION'
export const DISTRIBUTE_OBSTRUCTIONS = 'DISTRIBUTE_OBSTRUCTIONS'
export const UPDATE_OBSTRUCTION = 'UPDATE_OBSTRUCTION'
export const ADD_COMFORT_POINT = 'ADD_COMFORT_POINT'
export const UPDATE_COMFORT_POINT = 'UPDATE_COMFORT_POINT'
export const ADD_COMFORT_ZONE = 'ADD_COMFORT_ZONE'
export const UPDATE_COMFORT_ZONE = 'UPDATE_COMFORT_ZONE'
export const UPDATE_COMFORT_ZONE_METRICS = 'UPDATE_COMFORT_ZONE_METRICS'
export const ADD_DIMENSION = 'ADD_DIMENSION'
export const UPDATE_DIMENSION = 'UPDATE_DIMENSION'
export const DELETE_DIMENSION = 'DELETE_DIMENSION'
export const ADD_CEILING = 'ADD_CEILING'
export const UPDATE_CEILING = 'UPDATE_CEILING'
export const DELETE_CEILING = 'DELETE_CEILING'
export const REQUEST_AIRFLOW = 'REQUEST_AIRFLOW'
export const RECEIVE_AIRFLOW = 'RECEIVE_AIRFLOW'
export const UPDATE_AIRFLOW_STATUS = 'UPDATE_AIRFLOW_STATUS'
export const SET_AIRFLOW_LAYER = 'SET_AIRFLOW_LAYER'
export const ADD_BACKGROUND_IMAGE = 'ADD_BACKGROUND_IMAGE'
export const DELETE_BACKGROUND_IMAGE = 'DELETE_BACKGROUND_IMAGE'
export const UPDATE_BACKGROUND_IMAGE = 'UPDATE_BACKGROUND_IMAGE'
export const UPDATE_PRODUCT_HEIGHT = 'UPDATE_PRODUCT_HEIGHT'
export const ADD_METADATA_IMAGE = 'ADD_METADATA_IMAGE'
export const REQUEST_HEAT_MAP = 'REQUEST_HEAT_MAP'
export const RECEIVE_HEAT_MAP = 'RECEIVE_HEAT_MAP'
export const UPDATE_HEAT_MAP_STATUS = 'UPDATE_HEAT_MAP_STATUS'
export const SET_HEAT_MAP_LAYER = 'SET_HEAT_MAP_LAYER'