// @ts-check

import Facility from './facility'
import Util from './util'

import store from '~/store'
import { zoomIn, zoomOut } from '~/store/camera'
import create from 'zustand'

import * as THREE from 'three'

/*
  Provides the capability of adding/removing 'floating' DOM elements to the main drawing canvas
  which follow nodes in the three.js scenegraph. This essentially allows the DOM elements to be
  attached to objects in the 3D scene.
*/

export type FloatingElement = {
  element: HTMLElement
  objectToFollow: THREE.Object3D
  objectId: string
  timeRemaining: number | undefined
  offset: { x: number; y: number }
  keypressHandlerRef: ((ev: KeyboardEvent) => void) | undefined
  clickHandlerRef: ((ev: MouseEvent) => void) | undefined
  wheelHandlerRef: ((ev: WheelEvent) => void) | undefined
  tabbable: boolean
}

type FloatingElementStore = {
  elements: FloatingElement[]
  addElement(element: FloatingElement): void
  removeElement(id: string): void
}

class FloatingElementManager {
  static useFloatingElements = create<FloatingElementStore>((set, get) => ({
    elements: [],
    addElement(element) {
      set(state => ({ elements: [...state.elements, element] }))
    },
    removeElement(id) {
      set(state => ({ elements: state.elements.filter(it => it.objectId !== id) }))
    },
  }))

  static showFloatingElement<K extends keyof HTMLElementTagNameMap>(
    objectId: string,
    objectToFollow: THREE.Object3D,
    handlers: {
      keypressHandler?: (ev: KeyboardEvent) => void
      clickHandler?: (ev: MouseEvent) => void
    } = {},
    type: K = 'input' as any,
    idModifier?: string,
    options: {
      lifetime?: number
      offset?: { x: number; y: number }
      reinitializeKeypressHandler?: boolean
      reinitializeClickHandler?: boolean
    } = {}
  ) {
    let id = objectId
    if (idModifier) {
      id = `${objectId}-${idModifier}`
    }

    let datum = FloatingElementManager.useFloatingElements.getState().elements.find(
      datum => datum.element.id === id
    )

    if (datum === undefined) {
      let element
      let keypressHandlerRef
      if (type === 'input') {
        element = FloatingElementManager._getTextInputWithId(id)
        keypressHandlerRef = FloatingElementManager.addKeypressHandler(
          element,
          undefined,
          handlers.keypressHandler,
        )
      } else {
        element = FloatingElementManager._getElementWithId(id, type)
      }

      const clickHandlerRef = FloatingElementManager.addClickHandler(
        element,
        undefined,
        handlers.clickHandler,
      )

      const wheelHandlerRef = FloatingElementManager.addWheelHandler(
        element,
        FloatingElementManager.wheelHandler,
        undefined,
      )

      const newDatum = {
        element,
        objectToFollow,
        objectId: id,
        timeRemaining: options.lifetime,
        keypressHandlerRef,
        clickHandlerRef,
        offset: options.offset || { x: 0.5, y: 0.5 },
        wheelHandlerRef,
        tabbable: type === 'input',
      }

      FloatingElementManager.useFloatingElements.getState().addElement(newDatum)
      datum = newDatum
    } else {
      // If we're already tracking this element, just refresh
      // its timeRemaining.
      datum.timeRemaining = options.lifetime

      if (options.reinitializeKeypressHandler) {
        FloatingElementManager.addKeypressHandler(
          datum.element,
          datum,
          handlers.keypressHandler
        )
      }

      if (options.reinitializeClickHandler) {
        FloatingElementManager.addClickHandler(
          datum.element,
          datum,
          handlers.clickHandler
        )
      }
    }

    return datum!.element
  }

  static hideFloatingElement(objectId: string, idModifier?: string) {
    let id = objectId
    if (idModifier) {
      id = `${objectId}-${idModifier}`
    }

    FloatingElementManager.useFloatingElements.getState().removeElement(id)
  }

  static addClickHandler(element: HTMLElement, datum?: FloatingElement,  clickHandler?: (ev: MouseEvent) => void) {
    if (!clickHandler) {
      return
    }

    if (datum && datum.clickHandlerRef) {
      element.removeEventListener('click', datum.clickHandlerRef)
    }

    return element.addEventListener('click', clickHandler), clickHandler
  }

  static addWheelHandler(element: HTMLElement, wheelHandler: (ev: WheelEvent) => void, datum?: FloatingElement) {
    if (datum && datum.wheelHandlerRef) {
      element.removeEventListener('wheel', datum.wheelHandlerRef)
    }

    return element.addEventListener('wheel', wheelHandler), wheelHandler
  }

  static addKeypressHandler(element: HTMLElement, datum?: FloatingElement, keypressHandler?: (ev: KeyboardEvent) => void) {
    if (datum && datum.keypressHandlerRef) {
      element.removeEventListener('keydown', datum.keypressHandlerRef)
    }

    const handler = (e: KeyboardEvent) => {
      // We don't want event bubbling up to the global keyboard shortcuts.
      e.stopPropagation()

      const TAB_KEY = 9

      if (e.which === TAB_KEY) {
        // Cycle between other floating text inputs
        e.preventDefault()

        const elements = FloatingElementManager.useFloatingElements.getState().elements
        const thisIndex = elements.findIndex(it => it.objectId === element.id)
        const nextIndex = elements.findIndex((it, idx) => it.tabbable && idx > thisIndex)
        const focusIndex =
          nextIndex === -1 ? elements.findIndex(it => it.tabbable) : nextIndex

        if (elements[focusIndex]?.element instanceof HTMLInputElement)
          elements[focusIndex]?.element.select()
      } else {
        // Use provided keypress handler
        // eslint-disable-next-line
        if (keypressHandler) {
          keypressHandler(e)
        }
      }
    }
    return element.addEventListener('keydown', handler), handler
  }

  static _getTextInputWithId(id: string) {
    const textInput = document.getElementById(id)
    if (textInput && (textInput instanceof HTMLInputElement)) {
      return textInput
    }

    const newInput = document.createElement('input')
    newInput.id = id
    newInput.type = 'text'
    newInput.className = 'floating-text-input mousetrap'
    return newInput
  }

  static _getElementWithId<K extends keyof HTMLElementTagNameMap>(id: string, type: K) {
    let element = document.getElementById(id)
    if (!element) {
      element = document.createElement(type)
      element.id = id
      if ('type' in element) {
        element.type = 'text'
      }
      element.className = `floating-text-element`
    }

    return element
  }

  static frameRendered(deltaMillis: number) {
    FloatingElementManager.useFloatingElements.getState().elements.forEach(feDatum => {
      const element = feDatum.element
      const object = ((Facility as any).current as Facility).findObjectWithId(feDatum.objectId)
      const objectToFollow: THREE.Object3D = object ? object.obj3d : feDatum.objectToFollow

      if (feDatum.timeRemaining !== undefined) {
        feDatum.timeRemaining -= deltaMillis

        if (feDatum.timeRemaining <= 0) {
          FloatingElementManager.hideFloatingElement(element.id)
        }
      }

      if (objectToFollow.userData.deleted || !objectToFollow.visible) {
        FloatingElementManager.hideFloatingElement(element.id)
      }
    })
  }

  static wheelHandler(event: WheelEvent) {
    if (event.deltaY < 0) {
      store.dispatch(zoomIn())
    } else if (event.deltaY > 0) {
      store.dispatch(zoomOut())
    }
  }
}

export default FloatingElementManager
