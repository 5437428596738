import { useAppSelector } from '~/store/hooks'
import { useLocation } from 'react-router-dom'
import routes from 'config/routes'
import { useCFDStatusFragment } from 'hooks/useCFDStatus'
import LayerKeys from '~/config/layerKeys'
import { Edge, isPointInPolygon } from '~/utils/pointInPolygon'
import { Button } from '~/ui/Button'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Icon } from '~/ui/Icon'

export const ConfigureCFDButton = () => {
  const { cfdStatus, complete } = useCFDStatusFragment()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const products = useAppSelector(({ objects }) => Object.values(objects.present.products))
  const wallEdges = useAppSelector(({ objects }) =>
    Object.values(objects.present.segments)
      .filter(({ layerKey }) => layerKey === LayerKeys.EXTERIOR_WALLS)
      .map<Edge>(({ outsetPoints: { end, start } }) => ({
        p1: [start.x, start.y],
        p2: [end.x, end.y],
      }))
  )
  const isProductsWithinBounds = products.every(({ position: { x, y } }) =>
    isPointInPolygon([x, y], wallEdges)
  )

  const errorMessage = cfdStatus?.isSomeInProgress
    ? 'Please cancel existing simulation before running a new one.'
    : wallEdges.length < 3
    ? 'Please complete the facility\'s exterior walls.'
    : products.length === 0
    ? 'Please add products to the facility.'
    : !isProductsWithinBounds
    ? 'A fan is out of bounds. Please delete or move the fan to a valid location.'
    : undefined

  return (
    <>
      <Button
        onPress={() => navigate(`${pathname.replace(/\/+$/, '')}${routes.modals.configureCFD}`)}
        isDisabled={Boolean(errorMessage) || !complete}
      >
        {!complete ? 'Loading...' : 'Configure CFD'}
      </Button>
      {errorMessage ? (
        <div className="flex gap-2 items-center outline outline-red-900 p-2 rounded bg-red-100">
          <Icon name="warn" size="16" className="text-red-900" />
          <p className="text-red-900">{errorMessage}</p>
        </div>
      ) : null}
    </>
  )
}
