import { useState } from "react"
import { DialogTrigger } from "react-aria-components"
import ObjectClassName from "~/config/objectClassNames"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { generateProductDimensions } from "~/store/objects/generateDimensions"
import { Button } from "~/ui/Button"
import { Dialog } from "~/ui/Dialog"
import { Icon } from "~/ui/Icon"
import { Popover } from "~/ui/Popover"
import { Switch } from "~/ui/Switch"

export function DimensionsTool() {
  const [walls, setWalls] = useState(false)
  const [products, setProducts] = useState(false)
  const [obstructions, setObstructions] = useState(false)
  const selected = useAppSelector(store => store.selectedObjects)
  const selectedProducts = selected.filter(selected => selected.className === ObjectClassName.PRODUCT)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  return (
    <DialogTrigger>
      <Button variant="card" className="text-center text-[10px] flex flex-col items-center w-[75px]">
        <Icon name="generateDimensions" size="16"/>
        GENERATE
      </Button>
      <Popover placement="end" className="dark">
        <Dialog className="flex flex-col space-y-2">
          <div>
            Draw annotations to...
          </div>
          <Switch isSelected={walls} onChange={setWalls}>
            Walls
          </Switch>
          <Switch isSelected={products} onChange={setProducts}>
            Products
          </Switch>
          <Switch isSelected={obstructions} onChange={setObstructions}>
            Obstructions
          </Switch>
          <Button isDisabled={(!walls && !products && !obstructions) || selectedProducts.length === 0 || loading} onPress={() => {
            setLoading(true)

            dispatch(generateProductDimensions({
              productIDs: selectedProducts.map(product => product.id),
              flags: {
                walls, products, obstructions
              }
            }))
              .then(() => setLoading(false), () => setLoading(false))
          }}>{loading ? 'Generating...' : 'Generate'}</Button>
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}