import type Facility from '~/components/DrawingCanvas/lib/facility'
import Units from '~/components/DrawingCanvas/lib/units'
import Util from '~/components/DrawingCanvas/lib/util'
import { Product, StoreProducts } from './types'
import {Vector3, Matrix3, PlaneGeometry, Mesh,MeshBasicMaterial} from 'three'
import { SIMULATION_TYPES } from '~/config/cfd'
import { PRODUCT_CATEGORIES, PRODUCT_TYPES } from '~/config/product'
import { DEFAULT_COOLING_FAN_SPEED, DEFAULT_DESTRAT_FAN_SPEED } from '~/lib/airflow/airflow'

const { EVAP, FAN, HEAT } = PRODUCT_CATEGORIES
const { DIRECTIONAL, OVERHEAD } = PRODUCT_TYPES

const getProductSimulationTypes = (product: Product )=> {
  const { type, category } = product
  const simulationTypes = new Set<keyof typeof SIMULATION_TYPES>()

  const isOverheadFan = type === OVERHEAD && category === FAN
  const isDirectionalFan = type === DIRECTIONAL && category === FAN
  const isUnitHeater = type === DIRECTIONAL && category === HEAT

  const isDestrat = isOverheadFan
  const isCooling = isDirectionalFan || isOverheadFan || category === EVAP
  const isRadiant = type === OVERHEAD && category === HEAT
  const isUnitHeat = isUnitHeater || isDirectionalFan || isOverheadFan

  if (isDestrat) {
    simulationTypes.add(SIMULATION_TYPES.destrat)
    simulationTypes.add(SIMULATION_TYPES.cooling)
  }
  if (isCooling) simulationTypes.add(SIMULATION_TYPES.cooling)
  if (isRadiant) simulationTypes.add(SIMULATION_TYPES.radiantHeat)
  if (isUnitHeat) simulationTypes.add(SIMULATION_TYPES.unitHeating)

  return simulationTypes
}

export type FormattedProducts = Awaited<ReturnType<typeof formatProducts>>

export const formatProducts = async (facility: Facility, storeProducts: StoreProducts) => {
  const ceilings = facility.getCeilings().filter(({ enabled }) => enabled)
  const facilityProducts = Object.fromEntries(
    facility.getProducts().map(product => [product.id, product])
  )
  return await Promise.all(Object.entries(storeProducts).filter(([id]) => !!facilityProducts[id]).map(async ([id, storeProduct]) => {
    const facilityProduct = facilityProducts[id] ?? {}
    await facilityProduct.construction
    const productClone = {
      ...storeProduct,
      ...facilityProduct,
      obj3d: facilityProduct.obj3d.clone(),
      position: facilityProduct.position.clone(),
    }

    // Make sure fan is at least two feet under the ceiling
    const fanCeilingHeight = ceilings.find(({ perimeterPoints }) =>
      Util.isPointInPolygon(productClone.position, perimeterPoints)
    )?.height
    const twoFeet = Units.feetToNative(2)
    const ceilingClearance = fanCeilingHeight
      ? fanCeilingHeight - productClone.position.z
      : -Infinity
    const isUnderCeiling = ceilingClearance > 0
    const isUnderTwoFeetClearance = ceilingClearance < twoFeet
    if (isUnderCeiling && isUnderTwoFeetClearance) {
      productClone.position.z = fanCeilingHeight! - twoFeet
    }
    const productHeight = Units.nativeToInches(productClone.position.z)

    // Use a placeholder to get the product normals
    const fanPlaceholderMesh = new Mesh(
      new PlaneGeometry(10, 10),
      new MeshBasicMaterial({ color: 0x00ff00 })
    )
    fanPlaceholderMesh.position.copy(productClone.position)
    // TODO: uncomment after refactoring ConfigureCFDForm.js. The current uploadToCFD function incorrectly tries to apply rotation to a non-euler object
    // fanPlaceholderMesh.rotation.copy(facilityProduct.obj3d.rotation)

    // Edge case: only Pivot 2 has a tilt group based on being overhead
    const isPivot2 = /pivot.*2/gi.exec(facilityProduct.product.model)
    if (facilityProduct.isDirectional) {
      if (isPivot2 && facilityProduct.isDirectionalOverhead) {
        fanPlaceholderMesh.rotateX((180 * Math.PI) / 180)
      } else {
        fanPlaceholderMesh.rotateX((-90 * Math.PI) / 180)
      }
    } else {
      fanPlaceholderMesh.rotateX((-180 * Math.PI) / 180)
    }
    // Handle product rotation
    if (facilityProduct.isDirectional) {
      if (isPivot2 && facilityProduct.isDirectionalOverhead) {
        fanPlaceholderMesh.rotateZ(((360 - facilityProduct.rotation.z) * Math.PI) / 180)
      } else {
        fanPlaceholderMesh.rotateY(((360 - facilityProduct.rotation.z) * Math.PI) / 180)
      }
    }
    // Handle product tilt
    if (facilityProduct.tiltGroup) {
      fanPlaceholderMesh.rotateX(facilityProduct.tiltGroup.rotation.x)
    }
    fanPlaceholderMesh.updateMatrixWorld(true)
    const normalMatrix = new Matrix3().getNormalMatrix(fanPlaceholderMesh.matrixWorld)

    const fanMeshNormal = fanPlaceholderMesh?.geometry?.getAttribute('normal')?.array
    if (!fanMeshNormal) throw new Error('Failed to rotate fan during export!')
    const worldNormal = new Vector3(fanMeshNormal[0], fanMeshNormal[1], fanMeshNormal[2])
      .applyMatrix3(normalMatrix)
      .normalize()
    const productNormal = {
      nx: worldNormal.x,
      ny: worldNormal.y,
      nz: worldNormal.z,
    }
    const oneFootPadding = Units.inchesToNative(12)
    const positionClone = facilityProduct.obj3d.clone()
    if (facilityProduct.isDirectionalOverhead) {
      const isTilted = facilityProduct.rotation.x !== 0
      if (isTilted) {
        // If the directional overhead is facing perpendicular to
        // the floor put the point 1' in front of the product
        positionClone.translateY(oneFootPadding)
      } else {
        // If the directional overhead is facing down
        // to the floor, put the point 1' below it
        positionClone.position.z -= oneFootPadding
      }
    } else if (facilityProduct.isDirectional) {
      // Put point 1' in front of directional products
      positionClone.translateY(oneFootPadding)
    } else {
      // Put point 1' below overhead products
      positionClone.position.z -= oneFootPadding
    }
    return {
      cfdId: productClone.cfdId,
      simulationTypes: getProductSimulationTypes(productClone.product),
      unscaledPositionData: {
        ...positionClone.position,
      },
      rotation: storeProduct.rotation,
      positionData: {
        ...storeProduct.position,
        z: productHeight,
      },
      normals: {
        ...productNormal,
      },
      category: storeProduct.product?.category,
      size: storeProduct.size,
      angle: storeProduct.angle,
      variationId: productClone.variationId,
      heightFromFloor: Math.floor(productHeight),
      destratFanSpeed: productClone.destratFanSpeedId || DEFAULT_DESTRAT_FAN_SPEED,
      coolingFanSpeed: productClone.coolingFanSpeedId || DEFAULT_COOLING_FAN_SPEED,
    }
  }))
}
