import { DragControls, Html, ScreenSizer, Box } from "@react-three/drei";
import { useState, useRef, startTransition } from "react";
import { Vector3, Line3 } from "three";
import { useDistanceDimensions } from "~/hooks/useDistanceDimensions";
import { useAppDispatch } from "~/store/hooks";
import { updateDimension } from "~/store/objects";
import { Dimension } from "~/store/objects/types";
import { vectorModelToUI, vectorUIToModel } from "../util/units";

const DIMENSION_ANNOTATION_Z = 8
const LABEL_CLASSES = "w-fit whitespace-nowrap bg-white text-xs rounded tabular-nums px-2 py-1 pointer-events-none outline outline-1 outline-gray-300"

export function Label(props: { label: Dimension; pos: Vector3; }) {
  const { displayFormattedValue } = useDistanceDimensions();

  const { pos: labelPos, label } = props;
  const startPos = vectorModelToUI(label.startPos).setZ(DIMENSION_ANNOTATION_Z);
  const endPos = vectorModelToUI(label.endPos).setZ(DIMENSION_ANNOTATION_Z);
  const line = new Line3(startPos, endPos);
  const formatted = displayFormattedValue(new Vector3().subVectors(label.startPos, label.endPos).length());

  const [deltaAccumulator, setDeltaAccumulator] = useState<[number, number]>([0, 0]);
  const [down, setDown] = useState(false);
  const pointerDownAt = useRef(new Vector3());
  const dispatch = useAppDispatch();

  return (
    <>
      <DragControls autoTransform={false} axisLock="z"
        onDragStart={() => {
          setDown(true);
          pointerDownAt.current.setZ(DIMENSION_ANNOTATION_Z);
        }}
        onDrag={(_, deltaMatrix) => {
          const position = new Vector3();
          position.setFromMatrixPosition(deltaMatrix);
          startTransition(() => {
            setDeltaAccumulator(() => [position.x, position.y]);
          });
        }}
        onDragEnd={() => {
          const position = new Vector3();
          line.closestPointToPoint(pointerDownAt.current.clone().add({ x: deltaAccumulator[0], y: deltaAccumulator[1], z: 0 }), true, position);

          dispatch(updateDimension({
            dimension: {
              id: label.id,
              labelPos: vectorUIToModel(position),
            }
          }));

          setDown(false);
          setDeltaAccumulator([0, 0]);
        }}>
        <group visible={!down}>
          <ScreenSizer position={labelPos}>
            <Box args={[95, 55]} visible={false} onPointerDown={ev => { pointerDownAt.current.copy(ev.point); }} />
          </ScreenSizer>
          <Html center position={labelPos} zIndexRange={[0,0]}>
            {!down && <div className={LABEL_CLASSES}>{formatted}</div>}
          </Html>
        </group>
      </DragControls>
      {down && (() => {
        const position = new Vector3();
        line.closestPointToPoint(pointerDownAt.current.clone().add({ x: deltaAccumulator[0], y: deltaAccumulator[1], z: 0 }), true, position);

        return (
          <Html center position={position} zIndexRange={[0,0]}>
            <div className={LABEL_CLASSES}>{formatted}</div>
          </Html>
        );
      })()}
    </>
  );
}
