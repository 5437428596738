import { Line, DragControls, Circle, Html } from "@react-three/drei";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { Dimension } from "~/store/objects/types";
import { vectorModelToUI, vectorUIToModel } from "../util/units";
import { Color, Vector3 } from "three";
import { startTransition, useRef, useState } from "react";
import { updateDimension } from "~/store/objects";
import { useCursor, useProductSnapPoints, useWallSegmentEdgeSnapLines } from "../hooks";
import { snapTo, snapToCardinals } from "../util/snaplines";
import { useModifiers } from "../hooks/modifiers";
import { selectObjects } from "~/store/selectedObjects";
import ObjectClassName from "~/config/objectClassNames";
import { Label } from "./Label";
import { Arrow } from "./Arrow";
import { getThreeHexFromTheme } from "~/lib/utils";
import { useDistanceDimensions } from "~/hooks/useDistanceDimensions";

export function DimensionLabels() {
  const labels = useAppSelector(it => it.objects.present.dimensions)

  return (
    <>
      {Object.entries(labels).map(([key, value]) => <Annotation key={key} label={value}/>) }
    </>
  )
}

const ANCHOR_EPSILON = 4
const DIMENSION_ANNOTATION_Z = 8
const DRAG_HANDLE_SIZE_SQUARED = 7 ** 2

function Annotation(props: { label: Dimension }) {
  const startPos = vectorModelToUI(props.label.startPos).setZ(DIMENSION_ANNOTATION_Z)
  const endPos = vectorModelToUI(props.label.endPos).setZ(DIMENSION_ANNOTATION_Z)
  const anchorStartPos = props.label.anchorStartPos && vectorUIToModel(vectorModelToUI(props.label.anchorStartPos)).setZ(DIMENSION_ANNOTATION_Z)
  const anchorEndPos = props.label.anchorEndPos && vectorUIToModel(vectorModelToUI(props.label.anchorEndPos)).setZ(DIMENSION_ANNOTATION_Z)
  const labelPos = props.label.labelPos && vectorModelToUI(props.label.labelPos).setZ(DIMENSION_ANNOTATION_Z)
  const [down, setDown] = useState<'start' | 'end' | 'middle' | false>(false)
  const [deltaAccumulator, setDeltaAccumulator] = useState([0, 0] as [number, number])
  const pointerDownAt = useRef(new Vector3())
  const dispatch = useAppDispatch()
  const snaplines = useWallSegmentEdgeSnapLines()
  const snappoints = useProductSnapPoints()
  const { displayFormattedValue } = useDistanceDimensions()
  const shiftDown = useModifiers(modifiers => modifiers.shift)

  const currentPointRaw = () => pointerDownAt.current.clone().add({ x: deltaAccumulator[0], y: deltaAccumulator[1], z: 0 })
  const currentPoint = () => {
    const result = (() => {
      if (shiftDown && down === 'start') {
        return snapToCardinals(endPos, currentPointRaw())
      } else if (shiftDown && down === 'end') {
        return snapToCardinals(startPos, currentPointRaw())
      } else {
        return currentPointRaw()
      }
    })()
    const snapPoint = down ? snapTo(snaplines, snappoints, result) : undefined
    if (snapPoint && snapPoint.distance <= 100) {
      return [snapPoint, snapPoint.snapPoint] as const
    }
    return [undefined, result] as const
  }
  const [snapPoint, current] = currentPoint()

  const orientation = new Vector3().subVectors(startPos, endPos).normalize().applyAxisAngle(new Vector3(0, 0, 1), -Math.PI / 2)
  const center = new Vector3().addVectors(startPos, endPos).divideScalar(2)

  const selected = useAppSelector(it => it.selectedObjects.some((sel: { id: string }) => sel.id === props.label.id))
  const color =
    selected ?
      getThreeHexFromTheme('three.selected') :
      getThreeHexFromTheme('three.dark')

  const isVisible = useAppSelector(it => it.layers.layers.DIMENSION_LINES.visible)
  const isLocked = useAppSelector(it => it.layers.layers.DIMENSION_LINES.locked)
  const handlers = useCursor()

  return (
    <>
      <DragControls
        axisLock="z"
        autoTransform={false}
        onDragStart={() => {
          if (isLocked) {
            return
          }
          pointerDownAt.current.setZ(DIMENSION_ANNOTATION_Z)
          const d1 = pointerDownAt.current.distanceToSquared(startPos)
          const d2 = pointerDownAt.current.distanceToSquared(endPos)
          if (d1 <= DRAG_HANDLE_SIZE_SQUARED && d1 < d2) {
            setDown('start')
          } else if (d2 <= DRAG_HANDLE_SIZE_SQUARED && d2 < d1) {
            setDown('end')
          } else {
            setDown('middle')
          }
        }}
        onDrag={(_, deltaMatrix) => {
          if (isLocked) {
            return
          }
          const position = new Vector3()
          position.setFromMatrixPosition(deltaMatrix)
          startTransition(() => {
            setDeltaAccumulator(() => [position.x, position.y])
          })
        }}
        onDragEnd={() => {
          if (isLocked) {
            return
          }
          const mousePos = current

          if (down === 'start') {
            dispatch(updateDimension({
              dimension: {
                id: props.label.id,
                startPos: vectorUIToModel(mousePos),
                labelPos: vectorUIToModel(mousePos.clone().add(endPos).divideScalar(2)),
                anchorStartPos: mousePos,
                anchorEndPos: vectorModelToUI(props.label.endPos),
              }
            }))
          } else if (down === 'end') {
            dispatch(updateDimension({
              dimension: {
                id: props.label.id,
                endPos: vectorUIToModel(mousePos),
                labelPos: vectorUIToModel(mousePos.clone().add(startPos).divideScalar(2)),
                anchorEndPos: mousePos,
                anchorStartPos: vectorModelToUI(props.label.startPos),
              }
            }))
          } else if (down === 'middle') {
            const p = mousePos
              .sub(center)
              .projectOnVector(orientation)

            dispatch(updateDimension({
              dimension: {
                id: props.label.id,
                startPos: vectorUIToModel(startPos.clone().add(p)),
                endPos: vectorUIToModel(endPos.clone().add(p)),
                labelPos: vectorUIToModel(startPos.clone().add(p).add(endPos.clone().add(p)).divideScalar(2)),
              }
            }))
          }

          setDown(false)
          setDeltaAccumulator([0, 0])
        }}>
        <group visible={!down && isVisible}>
          <Arrow points={[startPos, endPos]} color={color}/>
          <Line points={[startPos, endPos]} visible={false} lineWidth={25} {...isLocked ? {} : handlers} onPointerDown={ev => { pointerDownAt.current.copy(ev.point) }} onClick={() => dispatch(selectObjects({ objects: [{ ...props.label, className: ObjectClassName.DIMENSION }] }))}/>
          {!!(anchorStartPos && anchorEndPos && startPos.distanceToSquared(anchorStartPos) >= ANCHOR_EPSILON) &&
            <>
              <Line points={[startPos, anchorStartPos]} lineWidth={1} color="black"/>
              <Line points={[endPos, anchorEndPos]} lineWidth={1} color="black"/>
            </>}
        </group>
      </DragControls>
      {snapPoint && <Circle visible={isVisible} position={snapPoint.snapPoint} args={[2, 20]} material-color="cyan"/>}
      {labelPos && !down && isVisible && <Label label={props.label} pos={labelPos}/>}
      {down && isVisible && (() => {
        const positionLocation = new Vector3()
        const difference = new Vector3()
        if (down === 'start') {
          positionLocation.addVectors(current, endPos)
          difference.subVectors(current, endPos)
        } else if (down === 'end') {
          positionLocation.addVectors(startPos, current)
          difference.subVectors(startPos, current)
        } else if (down === 'middle') {
          const p = current.clone()
            .sub(center)
            .projectOnVector(orientation)

          positionLocation.addVectors(startPos.clone().add(p), endPos.clone().add(p))
          difference.subVectors(startPos.clone().add(p), endPos.clone().add(p))
        }
        positionLocation.divideScalar(2)
        const formatted = displayFormattedValue(vectorUIToModel(difference).length())

        return (
          <Html center position={positionLocation} zIndexRange={[0,0]}>
            <div className="w-[8ch] bg-white rounded-lg tabular-nums px-2 py-1 pointer-events-none outline outline-1 outline-black">{formatted}</div>
          </Html>
        );
      })()}
      {down === 'start' && <Arrow visible={isVisible} points={[current, endPos]}/>}
      {down === 'end' && <Arrow visible={isVisible} points={[startPos, current]}/>}
      {down === 'middle' && (() => {
        const p = current.clone()
          .sub(center)
          .projectOnVector(orientation)

        return (
          <>
            <Arrow visible={isVisible} points={[startPos.clone().add(p), endPos.clone().add(p)]}/>
            <Line visible={isVisible} points={[anchorStartPos ?? startPos, startPos.clone().add(p)]} lineWidth={1} color="black"/>
            <Line visible={isVisible} points={[anchorEndPos ?? endPos, endPos.clone().add(p)]} lineWidth={1} color="black"/>
          </>
        )
      })()}
    </>
  )
}
