import * as THREE from 'three'
import get from 'lodash-es/get'

import RenderLoop from './renderLoop'
import Units from './units'
import FloatingElementManager from './floatingElementManager'
import getRenderOrder from 'config/canvasRenderOrder'
import OBJECT_TYPES from 'config/objectTypes'
import { getThreeHexFromTheme } from 'lib/utils'
import { getSelectedObjects } from 'store/selectedObjects/selectors'

class ArrowRenderer {
  static obj3d = new THREE.Object3D()
  static subscribers = new Set()
  static arrowPool = new Map()
  static UPDATE_INTERVAL = 1
  static ARROW_CONE_LENGTH = Units.feetToNative(1)
  static ARROW_CONE_WIDTH = Units.feetToNative(1)
  static ARROW_CONE_SMALL_LENGTH = Units.feetToNative(0.5)
  static ARROW_CONE_SMALL_WIDTH = Units.feetToNative(0.5)
  static frameIndex = 0
  static singleFrameArrowDescriptions = []

  static init() {
    RenderLoop.subscribe(ArrowRenderer)
  }

  static createNewArrow(id, key) {
    const dir = new THREE.Vector3()
    const origin = new THREE.Vector3()
    const arrowVisual = new THREE.ArrowHelper(
      dir,
      origin,
      1,
      getThreeHexFromTheme('three.dark')
    )

    // Don't add arrow helpers if too small to prevent scaling by 0 warning
    const lineScale = arrowVisual.line.scale
    if (lineScale.x <= 0 || lineScale.y <= 0) return

    arrowVisual.cone.material = new THREE.MeshStandardMaterial({
      metalness: 0.5,
      roughness: 0.5,
      color: getThreeHexFromTheme('three.dark'),
    })
    arrowVisual.userData.objectType = OBJECT_TYPES.UI_ARROW

    arrowVisual.line.userData.objectType = OBJECT_TYPES.UI_ARROW_LINE

    arrowVisual.line.material.transparent = true
    arrowVisual.line.material.depthTest = false
    arrowVisual.line.renderOrder = getRenderOrder('arrowRenderer')

    arrowVisual.cone.userData.objectType = OBJECT_TYPES.UI_ARROW_CONE
    arrowVisual.cone.material.transparent = true
    arrowVisual.cone.material.depthTest = false
    arrowVisual.cone.renderOrder = getRenderOrder('arrowRenderer')

    const anchorNode = new THREE.Object3D()
    arrowVisual.add(anchorNode)
    arrowVisual.userData.anchorNode = anchorNode
    ArrowRenderer.arrowPool.get(id).set(key, arrowVisual)
    ArrowRenderer.obj3d.add(arrowVisual)
  }

  /*
    Called by RenderLoop every frame
  */
  static frameRendered(deltaTime) {
    if (ArrowRenderer.frameIndex % ArrowRenderer.UPDATE_INTERVAL === 0) {
      ArrowRenderer.subscribers.forEach(subscriber => {
        const arrowMap = ArrowRenderer.arrowPool.get(subscriber.id)
        const isSelectTool = get(subscriber, 'name') === 'SELECT_TOOL'

        if (arrowMap) {
          for (const arrowVisual of arrowMap.values()) {
            if (isSelectTool) {
              if (getSelectedObjects().length < 2) {
                arrowVisual.visible = false
              }
            } else {
              arrowVisual.visible = false
              arrowVisual.userData.anchorNode.visible = false
            }
          }
        }

        const arrowDescriptions =
          typeof subscriber.getArrowDescriptions === 'function'
            ? subscriber.getArrowDescriptions()
            : undefined
        if (arrowDescriptions && arrowDescriptions.length) {
          ArrowRenderer.updateArrows(arrowDescriptions, subscriber)
          subscriber.arrowsRendered = !!arrowDescriptions.length
        }
      })

      if (this.singleFrameArrowDescriptions.length) {
        ArrowRenderer.updateArrows(this.singleFrameArrowDescriptions)
      }

      this.singleFrameArrowDescriptions.length = 0
    }

    ArrowRenderer.frameIndex += 0.5
  }

  static updateArrows(arrowDescriptions, subscriber) {
    const arrowMap = subscriber
      ? ArrowRenderer.arrowPool.get(subscriber.id)
      : new Map()

    if (!arrowMap) {
      ArrowRenderer.unsubscribe(subscriber.id)
      return
    }

    for (const arrowDescription of arrowDescriptions) {
      if (!arrowDescription.key) {
        continue
      }

      if (!arrowMap.has(arrowDescription.key)) {
        ArrowRenderer.createNewArrow(subscriber.id, arrowDescription.key)
      }
      const options = arrowDescription.options || {}
      const shiftProportion = !isNaN(arrowDescription.shiftProportion)
        ? arrowDescription.shiftProportion
        : 0.7
      const arrowVisual = arrowMap.get(arrowDescription.key)
      const isShortDistance = arrowDescription.vector.length() < 3
      arrowVisual.setLength(
        arrowDescription.vector.length(),
        isShortDistance
          ? ArrowRenderer.ARROW_CONE_SMALL_LENGTH
          : ArrowRenderer.ARROW_CONE_LENGTH,
        isShortDistance
          ? ArrowRenderer.ARROW_CONE_SMALL_WIDTH
          : ArrowRenderer.ARROW_CONE_WIDTH
      )

      // Don't update arrow helpers if too small to prevent scaling by 0 warning
      const lineScale = arrowVisual.line.scale
      if (lineScale.x <= 0 || lineScale.y <= 0) return

      arrowVisual.setDirection(arrowDescription.vector.clone().normalize())
      arrowVisual.position.copy(arrowDescription.position)
      arrowVisual.visible = true
      arrowVisual.userData.anchorNode.position.copy(
        new THREE.Vector3(
          0,
          arrowDescription.vector.length() * shiftProportion,
          0
        )
      )
      arrowVisual.userData.anchorNode.visible = true

      if (arrowDescription.showLength) {
        const lengthStr = Units.toDistanceString(
          arrowDescription.vector.length()
        )
        const elementType = arrowDescription.editable ? 'input' : 'label'
        // If an id is not supplied to arrowDescription,
        // and the arrowDescription happens to be at a different index than the previous frame,
        // the input may display the incorrect information and be the wrong type.
        // We may need to think of a better approach to this
        const id =
          arrowDescription.id !== undefined
            ? arrowDescription.id
            : arrowVisual.uuid
        const element = FloatingElementManager.showFloatingElement(
          id,
          arrowVisual.userData.anchorNode,
          { keypressHandler: arrowDescription.keyPressHandler },
          elementType,
          undefined,
          {
            lifetime: 250,
            reinitializeKeypressHandler: options.reinitializeKeypressHandler,
          }
        )

        // Update the displayed length, but not if the element is focused
        // since a user may be trying to insert new text.
        if (element !== document.activeElement) {
          const textProperty = arrowDescription.editable ? 'value' : 'innerHTML'
          element[textProperty] = lengthStr

          if (
            !subscriber.arrowsRendered &&
            arrowDescription.autofocus &&
            arrowDescription.editable
          ) {
            element.select()
          }
        }
      }
    }
  }

  static pushArrowDescriptions(arrowDescriptions) {
    this.singleFrameArrowDescriptions = this.singleFrameArrowDescriptions.concat(
      arrowDescriptions
    )
  }

  static subscribe(subscriber) {
    ArrowRenderer.arrowPool.set(subscriber.id, new Map())
    ArrowRenderer.subscribers.add(subscriber)
  }

  static unsubscribe(subscriber) {
    const id = get(subscriber, 'id')
    const arrowMap = ArrowRenderer.arrowPool.get(id)
    if (arrowMap) {
      for (const arrowVisual of arrowMap.values()) {
        ArrowRenderer.obj3d.remove(arrowVisual)
        arrowVisual.userData.anchorNode.userData.deleted = true
      }
    }
    ArrowRenderer.arrowPool.delete(id)
    ArrowRenderer.subscribers.delete(subscriber)
  }

  static destroy() {
    ArrowRenderer.subscribers.forEach(subscriber =>
      ArrowRenderer.unsubscribe(subscriber)
    )
    RenderLoop.unsubscribe(ArrowRenderer)
  }
}

export default ArrowRenderer
